import { prop } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { locationModule } from './module'

const { state$ } = locationModule

const currentLocation$ = select(state$, (state) => prop('currentLocation', state))

export { currentLocation$ }
