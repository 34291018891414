import { prop, path } from '@soltalabs/ramda-extra'
import { createModule } from '@soltalabs/stateless'

import { SearchService } from './service'

import { LOCATION } from 'utils/constants'

const defaultPaging = { page: 1, limit: 15 }

const SEARCH_PARAMS_INITIAL_STATE = {
  from: undefined,
  to: undefined,
  type: undefined,
  distance: 100,
  categories: undefined,
  sortBy: 'distance',
  sortOrder: 1,
  page: 1,
  limit: 15,
  relevancy: undefined,
  query: undefined,
}

const INITIAL_STATE = Object.freeze({
  autoCompletions: [],
  searchResults: [],
  searchSuggestions: [],
  resultsCount: 0,
  searchResultsPagination: {
    page: defaultPaging.page,
    limit: defaultPaging.limit,
  },
  searchParams: SEARCH_PARAMS_INITIAL_STATE,
  location: LOCATION.DEFAULT_LOCATION,
  locationAddress: undefined,
  isSearchStateLost: true,
})

const setSearchParams = (module) => (_, params) => {
  module.setState({
    searchParams: params,
  })
}

const resetSearchParams = (module) => () => {
  module.setState({
    searchParams: SEARCH_PARAMS_INITIAL_STATE,
    location: LOCATION.DEFAULT_LOCATION,
    locationAddress: undefined,
    searchResultsPagination: defaultPaging,
  })
}

const setSortBy = (module) => (sortBy) => {
  const { searchParams } = module.getState
  module.setState({
    searchParams: { ...searchParams, sortBy },
  })
}

const setLocation = (module) => (_, location) => {
  module.setState({
    location: {
      lat: location.lat,
      lng: location.lng,
    },
  })
}

const setLocationAddress = (module) => (_, address) => {
  module.setState({
    locationAddress: address,
  })
}

const setSearchResultsPagination = (module) => (_, pagination) => {
  module.setState({
    searchResultsPagination: pagination,
  })
}

const fetchAutoCompletions = (module) => async (query) => {
  const { items } = await SearchService.getAutoCompletions(query)

  module.setState({
    autoCompletions: items.map(prop('_id')),
  })
}

const fetchSearchSuggestions = (module) => async () => {
  const { items } = await SearchService.getSearchSuggestions()

  module.setState({
    searchSuggestions: items,
  })
}

const searchListings = (module) => async () => {
  const { searchParams, location } = module.getState()
  const listings = await SearchService.search({
    ...searchParams,
    ...location,
  })

  await module.setState({
    resultsCount: path([0, 'totalCount', 0, 'count'], listings),
    searchResults: listings[0].paginatedResults,
  })

  return listings[0]
}

const setIsSearchStateLost = (module) => async (_, isSearchStateLost) => {
  module.setState({
    isSearchStateLost,
  })
}

const searchModule = createModule({
  name: 'search',
  initialState: INITIAL_STATE,
  decorators: {
    setSearchParams,
    setSortBy,
    setLocation,
    fetchAutoCompletions,
    fetchSearchSuggestions,
    searchListings,
    resetSearchParams,
    setLocationAddress,
    setIsSearchStateLost,
    setSearchResultsPagination,
  },
})

export { searchModule }
