import React from 'react'

import { Row, Col } from 'lib/react-grid'
import { styled, s, smaller } from 'lib/styled'
import { STYLE_CONFIG } from 'styles/config'

const Container = styled.div(
  s('relative flex flex-column mt-8 mb-8', {
    maxWidth: STYLE_CONFIG.MAX_WIDTH,
  })
)

const OptionsContainer = styled.div(s('flex flex-row text-base'))

const OptionListItem = styled.div(s('mb-4'))

const Line = styled.hr(s('text-grey-light'), { opacity: 0.2 })

const Header = styled.p(
  s('text-black font-semibold', {
    fontSize: '28px',
  }),
  smaller('md')(s('text-xl'))
)

const BookingMessage = styled.span(s('text-sm mb-2'))

const PriceContainer = styled.div(s('mb-4 text-base'))
const Price = styled.span(s('text-lg font-bold'))

const HighlightedItem = styled.div(
  s('flex rounded-md m-2 py-2 px-4 ml-0 font-medium', {
    background: '#DEE5EF',
  })
)
const Bold = styled.span(s('font-bold'))

const OptionName = styled.span(s('text-base font-semibold'))

function ProductOptions({ selectedProduct }) {
  return (
    <Container>
      <Row gutter={20} style={{ width: '100%' }}>
        <Col md={24} lg={16} xl={16} style={{ width: '100%' }}>
          <Header>Options</Header>
          <PriceContainer>
            Starting <Price>FJD ${selectedProduct.price}/</Price>
            <Bold>person</Bold>
          </PriceContainer>
          <BookingMessage>
            Available options; please download our app to book
          </BookingMessage>
          {selectedProduct.options?.map((option, index) => (
            <OptionListItem key={index}>
              <OptionName>{option.name}</OptionName>
              <OptionsContainer>
                {option.values.map((value, index) => (
                  <HighlightedItem key={index}>{value}</HighlightedItem>
                ))}
              </OptionsContainer>
            </OptionListItem>
          ))}
          <Line />
        </Col>
      </Row>
    </Container>
  )
}

export { ProductOptions }
