import { isNotNil } from '@soltalabs/ramda-extra'

import { http } from 'lib/http-client'

const ProductService = {
  async get(id, location) {
    const locationParams = new URLSearchParams()
    Object.entries(location).forEach(([key, value]) => {
      if (isNotNil(value)) {
        locationParams.append(key, value)
      }
    })

    const product = await http
      .get(`public/products/${id}?${locationParams.toString()}&populateProvider=true`)
      .json()
    return product
  },
}

export { ProductService }
