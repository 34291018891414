const LISTING_MEDIA_TYPES = {
  PHOTO: 'listing_media_photo',
  VIDEO: 'listing_media_video',
  BROCHURE: 'listing_media_brochure',
  TERMS_CONDITIONS: 'listing_media_terms_conditions',
  CANCELLATION: 'listing_media_cancellation',
  RISK_ASSESSMENT: 'resouce_media_risk_assessment',
  MISC_DOCUMENT: 'listing_media_misc_document',
}

const LISTING_MEDIA_CONTENT_TYPES = {
  PDF: 'application/pdf',
  TEXT: 'text/html',
}

const CHILD_ACTIVITY_CATEGORIES = {
  CLASSES_CLUBS: { title: 'Classes & Clubs', value: 'child_activity_classes_clubs' },
  PARTIES: { title: 'Parties', value: 'child_activity_parties' },
  ONLINE_ACTIVITIES: {
    title: 'Online Activities',
    value: 'child_activity_online_activities',
  },
  PARK: { title: 'Park', value: 'child_activity_park' },
  PLAY_GROUND: { title: 'Playground', value: 'child_activity_play_ground' },
  PUBLIC_EVENT: { title: 'Public event', value: 'child_activity_public_event' },
  AFTER_SCHOOL: {
    title: 'After School',
    value: 'after_school',
  },
  HOLIDAYS: { title: 'Holidays', value: 'holidays' },
  COMMUNITY: {
    title: 'Community',
    value: 'child_activity_play_ground',
  },
  FAMILY_ATTRACTIONS: {
    title: 'Family Attractions',
    value: 'child_activity_family_attractions',
  },
  EDUCATION_CARE: {
    title: 'Education & Care',
    value: 'child_activity_education_care',
  },
}

const LISTING_TYPE = {
  PROMOTED: 'promoted',
  ONLINE: 'online',
  RELEVANT: 'relevant',
}

const REQUEST_STATUS = {
  PENDING: 'pending',
  FULLFILLED: 'fulfilled',
  REJECTED: 'rejected',
}

const QUERY_TO_LISTING_SOURCE = {
  native: '',
  rezdy: 'listing_source_rezdy',
}

const LISTING_SOURCE_TO_QUERY = {
  undefined: 'native',
  // eslint-disable-next-line camelcase
  listing_source_rezdy: 'rezdy',
}

const LOCATION = {
  DEFAULT_LOCATION: { lat: -18.1416, lng: 178.441895 },
}

export {
  LISTING_MEDIA_TYPES,
  LISTING_MEDIA_CONTENT_TYPES,
  CHILD_ACTIVITY_CATEGORIES,
  LISTING_TYPE,
  REQUEST_STATUS,
  QUERY_TO_LISTING_SOURCE,
  LISTING_SOURCE_TO_QUERY,
  LOCATION,
}
