import { path, isNilOrEmpty } from '@soltalabs/ramda-extra'
import React from 'react'

import { Row, Col } from 'lib/react-grid'
import { styled, s, smaller } from 'lib/styled'

const ContactContainer = styled(Col)(s('mr-32 flex flex-column'))

const ContactInfoLine = styled.span(s('text-base font-normal'))

const NoteHeader = styled.p(s('text-black text-xs font-semibold p-0 m-0 mb-1 pt-5'))

const NoteText = styled.p(s('text-base font-normal'), smaller('md')(s('text-xs')))

const generateNote = (notes) => {
  if (isNilOrEmpty(notes)) return null
  return (
    <div>
      {notes.map((text, index) => {
        return <NoteText key={index}>{text}</NoteText>
      })}
    </div>
  )
}

function ListingInfo({ selectedLocation }) {
  const transportNotes = path(['venue', 'transports'])(selectedLocation)

  const parkingNotes = path(['venue', 'parkings'])(selectedLocation)

  const contact = path(['contact'], selectedLocation)

  return (
    <>
      <Row>
        <ContactContainer>
          <NoteHeader hidden={isNilOrEmpty(contact)}>Contact Information</NoteHeader>
          <ContactInfoLine>{path(['name'], contact)}</ContactInfoLine>
          <ContactInfoLine>{path(['phone'], contact)}</ContactInfoLine>
          <ContactInfoLine>{path(['email'], contact)}</ContactInfoLine>
        </ContactContainer>
        <Col>
          <NoteHeader hidden={isNilOrEmpty(transportNotes)}>
            Transport Notes:
          </NoteHeader>

          {generateNote(transportNotes)}

          <NoteHeader hidden={isNilOrEmpty(parkingNotes)}>Parking Notes:</NoteHeader>

          {generateNote(parkingNotes)}
        </Col>
      </Row>
    </>
  )
}

export { ListingInfo }
