import { all, isNotNilOrEmpty, isTrue, or } from '@soltalabs/ramda-extra'
import mapboxgl from 'mapbox-gl'
import React, { useState } from 'react'
import ReactMapboxGl, { Popup, ZoomControl } from 'react-mapbox-gl'

import { CONFIG } from '../../config'

import { MarkerLayer } from './MarkerLayer'
import { getSvgMarkerOptions } from './utils'

import { Card } from 'lib/components'
import { s } from 'lib/styled'
import { getActivitySubtitle } from 'utils/activityMetadataBuilder'

const MapboxMap = ReactMapboxGl({
  accessToken: CONFIG.MAP_BOX.TOKEN,
  minZoom: 10,
  maxZoom: 20,
})

const Map = ({
  markerCoords = [],
  centerCoords = [],
  searchLocation = [],
  containerStyle,
  markerSvg,
  markerName,
  mapData,
  ...otherMapOptions
}) => {
  const [selectedMarker, selectMarker] = useState(0)
  const [shouldShowPopup, togglePopup] = useState(false)
  const { layerImagesOption, layoutOption } = getSvgMarkerOptions()

  const noMarker = markerCoords.length === 0
  const singleMarker = markerCoords.length === 1
  const hasMarker = or(
    all(isTrue)([
      isNotNilOrEmpty(layoutOption),
      isNotNilOrEmpty(layerImagesOption),
      !noMarker,
    ])
  )(isNotNilOrEmpty(centerCoords))
  const markerPositions = isNotNilOrEmpty(centerCoords) ? [centerCoords] : markerCoords
  const useFitBounds = markerCoords.length > 1

  const center = () => {
    if (isNotNilOrEmpty(centerCoords)) return centerCoords

    if (noMarker && isNotNilOrEmpty(searchLocation)) {
      return searchLocation
    }
    if (singleMarker) {
      return markerCoords[0]
    }
    return undefined
  }

  const getMarkerPopupCard = (i) => {
    const { title, name, address, photo, distance } = mapData[i]
    const subtitle = getActivitySubtitle(address, distance)
    return <Card title={title ?? name} subTitle={subtitle} imgSrc={photo} tags={[]} />
  }

  const bounds = markerCoords.reduce((bounds, coord) => {
    return bounds.extend(coord)
  }, new mapboxgl.LngLatBounds())
  return (
    <MapboxMap
      style={CONFIG.MAP_BOX.STYLE_URL}
      containerStyle={containerStyle}
      center={center()}
      fitBounds={useFitBounds ? bounds.toArray() : undefined}
      {...otherMapOptions}
    >
      <MarkerLayer
        layoutOption={layoutOption}
        layerImagesOption={layerImagesOption}
        markerPositions={markerPositions}
        visible={hasMarker}
        onClick={(i) => {
          togglePopup(!shouldShowPopup)
          selectMarker(i)
        }}
      />
      {shouldShowPopup && (
        <Popup coordinates={markerPositions[selectedMarker]} style={s('w-16 h-24 p-0')}>
          {getMarkerPopupCard(selectedMarker)}
        </Popup>
      )}
      <ZoomControl
        style={{
          position: 'absolute',
          right: 28,
          top: 37,
          borderRadius: 4,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        }}
      />
    </MapboxMap>
  )
}

export { Map }
