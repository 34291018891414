export const locationMarkerSvg = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="25" height="55" viewBox="0 0 45 73" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f)">
<ellipse cx="22.3484" cy="62.3408" rx="11.7625" ry="2.35249" fill="#862D8A" fill-opacity="0.4"/>
</g>
<mask id="path-2-inside-1" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.3487 62.257C34.6915 62.257 44.6973 34.6915 44.6973 22.3487C44.6973 10.0058 34.6915 0 22.3487 0C10.0058 0 0 10.0058 0 22.3487C0 34.6915 10.0058 62.257 22.3487 62.257ZM22.3487 31.9263C27.6385 31.9263 31.9267 27.6381 31.9267 22.3483C31.9267 17.0585 27.6385 12.7703 22.3487 12.7703C17.0589 12.7703 12.7707 17.0585 12.7707 22.3483C12.7707 27.6381 17.0589 31.9263 22.3487 31.9263Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.3487 62.257C34.6915 62.257 44.6973 34.6915 44.6973 22.3487C44.6973 10.0058 34.6915 0 22.3487 0C10.0058 0 0 10.0058 0 22.3487C0 34.6915 10.0058 62.257 22.3487 62.257ZM22.3487 31.9263C27.6385 31.9263 31.9267 27.6381 31.9267 22.3483C31.9267 17.0585 27.6385 12.7703 22.3487 12.7703C17.0589 12.7703 12.7707 17.0585 12.7707 22.3483C12.7707 27.6381 17.0589 31.9263 22.3487 31.9263Z" fill="#943798"/>
<path d="M43.6973 22.3487C43.6973 28.3292 41.25 38.1568 37.2499 46.499C35.2539 50.6615 32.9008 54.3905 30.3274 57.0634C27.7437 59.747 25.0475 61.257 22.3487 61.257V63.257C25.8212 63.257 29.0043 61.3213 31.7682 58.4506C34.5422 55.5693 37.0049 51.6355 39.0532 47.3638C43.1417 38.8375 45.6973 28.711 45.6973 22.3487H43.6973ZM22.3487 1C34.1392 1 43.6973 10.5581 43.6973 22.3487H45.6973C45.6973 9.45355 35.2438 -1 22.3487 -1V1ZM1 22.3487C1 10.5581 10.5581 1 22.3487 1V-1C9.45355 -1 -1 9.45355 -1 22.3487H1ZM22.3487 61.257C19.6498 61.257 16.9536 59.747 14.3699 57.0634C11.7965 54.3905 9.44344 50.6615 7.44747 46.499C3.4473 38.1568 1 28.3292 1 22.3487H-1C-1 28.711 1.55562 38.8375 5.64408 47.3638C7.69241 51.6355 10.1551 55.5693 12.9292 58.4506C15.693 61.3213 18.8761 63.257 22.3487 63.257V61.257ZM30.9267 22.3483C30.9267 27.0858 27.0862 30.9263 22.3487 30.9263V32.9263C28.1908 32.9263 32.9267 28.1904 32.9267 22.3483H30.9267ZM22.3487 13.7703C27.0862 13.7703 30.9267 17.6108 30.9267 22.3483H32.9267C32.9267 16.5062 28.1908 11.7703 22.3487 11.7703V13.7703ZM13.7707 22.3483C13.7707 17.6108 17.6112 13.7703 22.3487 13.7703V11.7703C16.5066 11.7703 11.7707 16.5062 11.7707 22.3483H13.7707ZM22.3487 30.9263C17.6112 30.9263 13.7707 27.0858 13.7707 22.3483H11.7707C11.7707 28.1904 16.5066 32.9263 22.3487 32.9263V30.9263Z" fill="#862D8A" mask="url(#path-2-inside-1)"/>
<defs>
<filter id="filter0_f" x="2.58594" y="51.9883" width="39.5249" height="20.705" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur"/>
</filter>
</defs>
</svg>
`
export const selectedLocationMarkerSvg = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="25" height="55" viewBox="0 0 45 73" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f)">
<ellipse cx="22.3484" cy="62.3408" rx="11.7625" ry="2.35249" fill="#862D8A" fill-opacity="0.4"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.3487 62.257C34.6915 62.257 44.6973 34.6915 44.6973 22.3487C44.6973 10.0058 34.6915 0 22.3487 0C10.0058 0 0 10.0058 0 22.3487C0 34.6915 10.0058 62.257 22.3487 62.257ZM22.3487 31.9263C27.6385 31.9263 31.9267 27.6381 31.9267 22.3483C31.9267 17.0585 27.6385 12.7703 22.3487 12.7703C17.0589 12.7703 12.7707 17.0585 12.7707 22.3483C12.7707 27.6381 17.0589 31.9263 22.3487 31.9263Z" fill="#862D8A"/>
<defs>
<filter id="filter0_f" x="2.58594" y="51.9883" width="39.5249" height="20.705" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur"/>
</filter>
</defs>
</svg>
`
