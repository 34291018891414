import { filter, isNilOrEmpty, path, prop } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PulseLoader from 'react-spinners/PulseLoader'

import { ActivityGallery } from '../common/ActivityDetails/ActivityGallery'
import { ActivityLocations } from '../common/ActivityDetails/ActivityLocations'
import { ActivitySummary } from '../common/ActivityDetails/ActivitySummary'
import { ProviderDetails } from '../common/ActivityDetails/ProviderDetails'

import { ProductOptions } from './ProductOptions'
import { ProductSummary } from './ProductSummary'

import ShareIcon from 'assets/share.svg'
import { Alert } from 'lib/components'
import { styled, s, smaller } from 'lib/styled'
import { currentLocation$ } from 'modules/location'
import {
  selectedProduct$,
  productModule,
  isFetchingProductById$,
} from 'modules/product'
import { isOnMobile$, screenWidth$ } from 'modules/ui'
import { colors } from 'product.config'
import { LISTING_MEDIA_TYPES } from 'utils/constants'

const Container = styled.div(s('pb-8 h-full w-full', {}), smaller('md')(s('px-5')))

const LoadingContainer = styled.div(
  s('relative w-full flex justify-center items-center', {
    height: '80vh',
  })
)

const ShareContainer = styled.div(s('flex justify-end mt-4'), {
  '&:hover': { cursor: 'pointer' },
})

const AlertContainer = styled.div(s('flex justify-end mt-2'))

const ConnectedProductDetailsPage = connect(() => ({
  selectedProduct: selectedProduct$,
  isFetchingProductById: isFetchingProductById$,
  screenWidth: screenWidth$,
  isOnMobile: isOnMobile$,
  currentLocation: currentLocation$,
}))(ProductDetailsPage)

function ProductDetailsPage({
  selectedProduct,
  isFetchingProductById,
  screenWidth,
  isOnMobile,
  currentLocation,
}) {
  const [fetchProductByIdError, setFetchProductByIdError] = useState(undefined)
  const [showProductShared, setShouldShowProductShared] = useState(false)

  const params = useParams()
  const productId = path(['id'])(params)

  useEffect(() => {
    productModule.fetchProductById(productId, currentLocation).catch((error) => {
      setFetchProductByIdError(error)
    })
  }, [])
  const errorMessage = prop('message')(fetchProductByIdError)

  const share = async () => {
    setShouldShowProductShared(true)
    const urlToShare = document.location.href
    await navigator.clipboard.writeText(urlToShare)

    setTimeout(() => setShouldShowProductShared(false), 5000)
  }

  if (errorMessage) return <p>{errorMessage}</p>

  if (isFetchingProductById || isNilOrEmpty(selectedProduct))
    return (
      <LoadingContainer>
        <PulseLoader size={15} color={colors.primary} />
      </LoadingContainer>
    )

  const mediaList = filter(
    (item) =>
      item.mediaType === LISTING_MEDIA_TYPES.PHOTO ||
      item.mediaType === LISTING_MEDIA_TYPES.VIDEO,
    selectedProduct?.media
  )
  return (
    <Container>
      <AlertContainer>
        <Alert type="info" text="Copied to clipboard." visible={showProductShared} />
      </AlertContainer>
      <ShareContainer onClick={() => share()}>
        <img src={ShareIcon} />
        <span>Share</span>
      </ShareContainer>
      <ActivityGallery data={mediaList} maxWidth={screenWidth} />
      <ActivitySummary data={selectedProduct} isOnMobile={isOnMobile} />
      <ProductSummary selectedProduct={selectedProduct} />
      <ActivityLocations
        locations={{
          location: selectedProduct.location,
          address: selectedProduct.address,
          distance: selectedProduct.distance,
        }}
        isOnMobile={isOnMobile}
        selectedActivity={selectedProduct}
      />
      <ProductOptions selectedProduct={selectedProduct} />
      <ProviderDetails provider={selectedProduct.provider} />
    </Container>
  )
}

export { ConnectedProductDetailsPage as ProductDetailsPage }
