import { connect } from '@soltalabs/stateless'
import React from 'react'

import { screenWidth$ } from 'modules/ui'

const ConnectedBottomContent = connect(() => ({
  screenWidth: screenWidth$,
}))(BottomContent)

function BottomContent({ CopyRightBlock, LoveText, screenWidth }) {
  if (screenWidth > 1200) {
    return (
      <>
        <LoveText />
        <CopyRightBlock />
      </>
    )
  }

  return (
    <>
      <div>
        <LoveText />
        <CopyRightBlock />
      </div>
    </>
  )
}

export { ConnectedBottomContent as BottomContent }
