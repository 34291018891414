import React from 'react'
import NumberFormat from 'react-number-format'
import TextTruncate from 'react-text-truncate'

import { Chip } from 'lib/components'
import { styled, s } from 'lib/styled'

// this can be modified to use TS syntax (import dayjs = require("dayjs")) once project swapped to TS
const dayjs = require('dayjs')
const duration = require('dayjs/plugin/duration')
const relativeTime = require('dayjs/plugin/relativeTime')

const Container = styled.div(s('p-5'), {
  maxWidth: 248,
  maxHeight: 228,
  cursor: 'pointer',
  border: '1px solid #DEE5EF',
  borderRadius: '8px',
})

const Title = styled(TextTruncate)({
  maxWidth: '100%',
  height: '75px',
  fontFamily: 'Open Sans',
  fontSize: '18px',
  fontWeight: '400',
  lineHeight: '25px',
  letterSpacing: '-0.2200000286102295px',
  marginBottom: '6',
})

const StyledNumberFormat = styled(NumberFormat)({
  fontWeight: '400',
  fontSize: '15px',
  lineHeight: '20px',
  letterSpacing: '-0.22px',
  color: '#293854',
})

const PriceTag = styled.div(s('mt-4'), {
  color: '#293854',
  fontWeight: '400',
  fontSize: '20px',
  lineHeight: '27px',
  letterSpacing: '-0.22px',
})

const StyledPrice = styled(NumberFormat)({
  color: '#943798',
  fontWeight: '600',
  fontSize: '24px',
  lineHeight: '33px',
})

const getChip = (sourceType = 'Listings') => {
  return <Chip text={sourceType.toUpperCase()} variation="sourceType" />
}

const Card = ({ title, sourceType, price, address, distance, onClick }) => {
  dayjs.extend(duration)
  dayjs.extend(relativeTime)
  return (
    <Container onClick={onClick}>
      <div style={{ display: 'flex flex-column' }}>
        {getChip(sourceType)}
        <Title line={2} element="div" truncateText="..." text={title} />
        <StyledNumberFormat
          value={distance / 1000}
          displayType={'text'}
          thousandSeparator={true}
          prefix={`${address} (`}
          suffix="km)"
          decimalScale={0}
        />
        <PriceTag>
          <StyledPrice
            value={price?.$numberDecimal ? price?.$numberDecimal : price}
            displayType={'text'}
            thousandSeparator={true}
            prefix="$"
            decimalScale={2}
          />
          {' / person'}
        </PriceTag>
      </div>
    </Container>
  )
}

export { Card as ListCard }
