import { isNotNil } from '@soltalabs/ramda-extra'

import { http } from 'lib/http-client'
import { QUERY_TO_LISTING_SOURCE } from 'utils/constants'

const ListingService = {
  async get(id, listingSource = 'native', location) {
    const locationParams = new URLSearchParams()
    Object.entries(location).forEach(([key, value]) => {
      if (isNotNil(value)) {
        locationParams.append(key, value)
      }
    })

    const listing = await http
      .get(
        `public/listings/${id}?listingSource=${
          QUERY_TO_LISTING_SOURCE[listingSource]
        }&${locationParams.toString()}`
      )
      .json()

    return listing
  },

  async getListings({
    query,
    categories,
    type,
    price,
    from,
    to,
    distance = 100,
    lat,
    lng,
    next,
    sortBy,
    sortOrder,
    relevancy,
    provider,
  }) {
    const params = {
      q: query,
      categories,
      type,
      price,
      from,
      to,
      distance,
      lat,
      lng,
      next,
      sortBy,
      sortOrder,
      relevancy,
      provider,
    }

    const searchParams = new URLSearchParams()
    Object.entries(params).forEach(([key, value]) => {
      if (isNotNil(value)) {
        searchParams.append(key, value)
      }
    })

    const listings = await http
      .get(`public/listings/?${searchParams.toString()}`, { timeout: 30000 })
      .json()

    return listings
  },
}

export { ListingService }
