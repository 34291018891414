import parse from 'html-react-parser'
import React from 'react'

import { Row, Col } from 'lib/react-grid'
import { styled, s, smaller } from 'lib/styled'
import { STYLE_CONFIG } from 'styles/config'

const Container = styled.div(
  s('relative flex flex-column w-', {
    maxWidth: STYLE_CONFIG.MAX_WIDTH,
  })
)

const Description = styled.span(s('text-black text-base font-normal block'))

const Line = styled.hr(s('mb-8 text-grey-light'), { opacity: 0.2 })

const Header = styled.p(
  s('text-black font-semibold p-0 m-0 mb-4', {
    fontSize: '28px',
  }),
  smaller('md')(s('text-xl'))
)

function ProductSummary({ selectedProduct }) {
  return (
    <Container>
      <Row gutter={20} style={{ width: '100%' }}>
        <Col md={24} lg={16} xl={16} style={{ width: '100%' }}>
          <Header>Activity Details</Header>
          <Description>{parse(selectedProduct.summary)}</Description>
          <Line />
        </Col>
      </Row>
    </Container>
  )
}

export { ProductSummary }
