import React from 'react'

import { CONFIG } from '../../../config'

import { styled, s } from 'lib/styled'
import { colors } from 'product.config'
import { toExternalUrl } from 'utils/toExternalUrl'

const Container = styled.div(
  s('flex flex-column items-center max-w-48 text-center ml-a mr-a mb-32', {
    '@media screen and (max-width: 520px)': {
      width: '80%',
    },
  })
)

const SectionTitle = styled.h1(s('text-4xl font-semibold tracking-wide'), {
  lineHeight: '56px',
  color: colors.primary,
  '@media screen and (max-width: 1000px)': {
    fontSize: '1.8rem',
  },
  '@media screen and (max-width: 520px)': {
    fontSize: '1.5rem',
  },
})

const SectionIntro = styled.p(s('text-lg font-normal mb-4'), {
  lineHeight: '35px',
  '@media screen and (max-width: 1000px)': {
    fontSize: '.9rem',
    lineHeight: '28px',
  },
  '@media screen and (max-width: 780px)': {
    fontSize: '1.1rem',
    lineHeight: '32px',
  },
  '@media screen and (max-width: 520px)': {
    lineHeight: '25px',
    width: '80%',
    margin: '0 auto',
    fontSize: '.9rem',
    marginBottom: '2rem',
  },
})

const CtaBtn = styled.button(
  s('text-xl border-0 font-bold text-white rounded-md px-32 py-4'),
  {
    background: colors.primary,
    cursor: 'pointer',
    '@media screen and (max-width: 1000px)': {
      fontSize: '1rem',
    },
    '@media screen and (max-width: 780px)': {
      fontSize: '1.5rem',
    },
    '@media screen and (max-width: 520px)': {
      fontSize: '1rem',
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
    },
  }
)

function CtaSection() {
  return (
    <Container>
      <SectionTitle>List your business, activities and events with Bula</SectionTitle>
      <SectionIntro>
        Join Bula for free, create your listings, and explore the Bula benefits to
        support you and grow your business
      </SectionIntro>

      <CtaBtn onClick={() => toExternalUrl(`${CONFIG.PROVIDER.URL}signup`)}>
        Let&apos;s Start
      </CtaBtn>
    </Container>
  )
}

export { CtaSection }
