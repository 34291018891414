import React from 'react'

import { styled, s } from 'lib/styled'

const CardContainer = styled.div(({ placement }) =>
  s('mb-4, w-full', {
    transform: placement === 'bottom' ? 'translateY(50%)' : '',
    '@media screen and (max-width: 1200px)': {
      transform: placement === 'bottom' ? 'translateY(30%)' : '',
    },
    '@media screen and (max-width: 1000px)': {
      transform: 'translateY(0)',
      maxWidth: '510px',
    },
  })
)

const ImgContainer = styled.div(
  s('mb-4 w-full', {
    background: '#C4C4C4',
    borderRadius: '10px',
  })
)

const Image = styled.img(s('w-full'), {
  objectFit: 'cover',
  borderRadius: '10px',
})

const Title = styled.h1(s('text-xl font-semibold mb-4'), {
  color: '#112138',
  lineHeight: '28px',
  borderRadius: '10px',
})

const IntroText = styled.p({
  color: '#112138',
  '@media screen and (max-width: 520px)': {
    lineHeight: '24px',
    fontSize: '14px',
  },
})

function CaterListCard({ title, intro, imgSrc, placement, ...restProps }) {
  return (
    <CardContainer placement={placement} {...restProps}>
      <ImgContainer>
        <Image src={imgSrc} alt={title} />
      </ImgContainer>
      <Title>{title}</Title>
      <IntroText>{intro}</IntroText>
    </CardContainer>
  )
}

export { CaterListCard }
