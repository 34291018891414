import React from 'react'

import { styled, s } from 'lib/styled'

const Container = styled.div(s('flex'))
const Block = styled.div(s('flex'))
const Span = styled.span(s('pr-2'))

const DataBlock = ({ data }) => {
  return (
    <Container>
      {data.map((text, i) => (
        <Block key={i}>
          <Span>{text}</Span>

          {!(i === data.length - 1) && (
            <Span>
              <p>&#183;</p>
            </Span>
          )}
        </Block>
      ))}
    </Container>
  )
}

export { DataBlock }
