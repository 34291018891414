import { path, head } from '@soltalabs/ramda-extra'
import React, { useEffect, useMemo, useState } from 'react'
import ReactMapboxGl from 'react-mapbox-gl'

import { CONFIG } from '../../../config'
import { Map } from '../../map'

import { ListingInfo } from './ListingInfo'
import { MapNav } from './MapNav'

import LocationPinDetailed from 'assets/locationPinDetailed.svg'
import NoWheelchairAccess from 'assets/noWheelchairAccess.svg'
import { TextWithIcon } from 'lib/components/dataDisplay'
import { Row, Col } from 'lib/react-grid'
import { styled, s, smaller, larger } from 'lib/styled'

import 'mapbox-gl/dist/mapbox-gl.css'

const Container = styled.div(s('relative flex flex-column my-4 rounded-lg'), {
  overflow: 'hidden',
})

const Spacer = styled.div(s('h-0 mb-4'))
const Line = styled.hr(s(' mt-4 text-grey-light w-full'), { opacity: 0.2 })

const ContentContainer = styled(Row)(
  s('flex flex-column-reverse w-full'),
  larger('md')(s('flex-row'))
)

const DataContainer = styled.div(
  s('relative flex flex-column w-full justify-between pb-4 pt-8 px-0'),
  smaller('md')(s('', { width: '80vw' }))
)

const MapContainer = styled(Col)(
  {
    position: 'relative',
    height: '60vh',
    '& canvas': {
      maxWidth: '100% !important',
    },
  },
  smaller('sm')({ height: '40vh' })
)

const TopContainer = styled.div(s('flex flex-row w-full'), {})

const Header = styled.p(
  s('text-black text-2xl font-bold p-0 mb-2'),
  smaller('md')(s('text-xl'))
)

const reformatListingForMap = (selectedActivity, selectedLocation) => {
  const photo = path(['media', 0, 'content'], selectedActivity)
  return {
    ...selectedActivity,
    photo,
    distance: selectedLocation.distance,
    address: path(['venue', 'address'], selectedLocation) ?? selectedActivity.address,
  }
}

const MapboxMap = ReactMapboxGl({
  accessToken: CONFIG.MAP_BOX.TOKEN,
})

const checkWheelchairAccess = (access) => {
  return (
    access === false && (
      <TextWithIcon
        text={'Not wheelchair accessible'}
        icon={NoWheelchairAccess}
        textSize={14}
      />
    )
  )
}

function LocationDetail({ locations, isOnMobile, isListing, selectedActivity }) {
  const [selectedLocation, setSelectedLocation] = useState(head(locations))
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    setSelectedLocation(path([selectedIndex], locations))
  }, [selectedIndex])

  const reformattedActivity = useMemo(
    () => reformatListingForMap(selectedActivity, selectedLocation),
    [selectedLocation]
  )

  const coordinatePath = isListing
    ? ['venue', 'addressLocation', 'coordinates']
    : ['location', 'coordinates']

  const addressPath = isListing
    ? ['venue', 'address', 'formattedAddress']
    : ['address', 'formattedAddress']

  const coordinates = path(coordinatePath)(selectedLocation)

  const wheelchairAccess = path(['venue', 'wheelchairAccessible'], selectedLocation)

  const totalLocations = locations.length

  const onArrowNavClick = (change) => {
    setSelectedIndex(selectedIndex + change)
  }

  return (
    <Container>
      <ContentContainer justify="center">
        <MapContainer span={24} isOnMobile={isOnMobile} flex="auto">
          <Map
            MapboxMap={MapboxMap}
            centerCoords={coordinates}
            containerStyle={s('w-full h-full')}
            zoom={[15]}
            mapData={[reformattedActivity]}
          />
        </MapContainer>
      </ContentContainer>
      <ContentContainer>
        <DataContainer>
          <TopContainer>
            {isListing && (
              <>
                <Header>{path(['venue', 'name'])(selectedLocation)}</Header>
                <MapNav
                  currentIndex={selectedIndex}
                  totalLocations={totalLocations}
                  onNavClick={onArrowNavClick}
                />
              </>
            )}
          </TopContainer>

          <TextWithIcon
            text={path(addressPath)(selectedLocation)}
            icon={LocationPinDetailed}
            textSize={14}
            textWeight={600}
          />
          <Spacer />
          {checkWheelchairAccess(wheelchairAccess)}
          {isListing && <ListingInfo selectedLocation={selectedLocation} />}
        </DataContainer>
      </ContentContainer>
      <Line />
    </Container>
  )
}

export { LocationDetail }
