import { isNotNil } from '@soltalabs/ramda-extra'
import React from 'react'

import { styled, s } from 'lib/styled'
import { colors } from 'product.config'

const Container = styled.div(({ direction }) =>
  s('flex flex-row justify-around items-center mb-24', {
    flexDirection: `${direction}`,
    '@media screen and (max-width: 1120px)': {
      flexDirection: 'column',
    },
    '@media screen and (max-width: 600px)': {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
)

const ImgPane = styled.div(s('flex flex-column w-1/2'), {
  '@media screen and (max-width: 1120px)': {
    margin: '0 0 4rem 0',
    width: '100%',
    maxWidth: '560px',
  },
})

const Img = styled.img(s('w-full'))

const TextPane = styled.div({
  '@media screen and (max-width: 1120px)': {
    textAlign: 'center',
  },
})

const ImgMetaText = styled.span(s('text-base'), { lineHeight: '24px' })

const Title = styled.h1(s('text-3xl font-normal'), {
  color: colors.grey[800],
  lineHeight: '39.2px',
})

const Intro = styled.p(s('text-base pl-16 max-w-24'), {
  color: colors.grey[700],
  lineHeight: '24px',
  '@media screen and (max-width: 1120px)': {
    padding: 0,
  },
})

function JumboItem({ title, intro, imgSrc, imgMetaText, direction }) {
  return (
    <Container direction={direction}>
      <ImgPane>
        <Img src={imgSrc} alt={title} />
        {isNotNil(imgMetaText) && <ImgMetaText>{imgMetaText}</ImgMetaText>}
      </ImgPane>

      <TextPane>
        <Title>{title}</Title>
        <Intro>{intro}</Intro>
      </TextPane>
    </Container>
  )
}

export { JumboItem }
