import { findIndex } from '@soltalabs/ramda-extra'
import { useEffect } from 'react'

function useOnClickOutside(ref, targetsExcludedToHandler = [], handler) {
  useEffect(() => {
    const listener = (e) => {
      const targetList = [ref?.current, ...targetsExcludedToHandler]
      const notClickedInTarget =
        findIndex((target) => target?.contains(e.target))(targetList) === -1
      if (notClickedInTarget) handler()
    }

    document.addEventListener('mouseup', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mouseup', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, targetsExcludedToHandler])

  return null
}

export { useOnClickOutside }
