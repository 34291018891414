import { isNotNilOrEmpty } from '@soltalabs/ramda-extra'
import React from 'react'

import { styled, s } from 'lib/styled'
import { colors } from 'product.config'

const Container = styled.div(({ shouldUpdateHoverStyle }) =>
  s('relative flex flex-row items-center', {
    '&:hover': shouldUpdateHoverStyle
      ? s('', {
          cursor: 'pointer',
          backgroundColor: colors.hoverGrey,
        })
      : '',
  })
)

const Text = styled.span(({ primaryTheme }) =>
  s('text-grey-dark text-2xl font-normal ml-1', {
    color: primaryTheme ? colors.grey['100'] : 'black',
  })
)

const Icon = styled.img('', { height: 21, width: 21 })

const TextWithIcon = ({
  text,
  icon,
  textSize,
  handleOnClick,
  iconSize,
  textWeight,
  primaryTheme = false,
}) => {
  const shouldUpdateHoverStyle = isNotNilOrEmpty(handleOnClick)
  return (
    <Container onClick={handleOnClick} shouldUpdateHoverStyle={shouldUpdateHoverStyle}>
      <Icon style={{ height: iconSize, width: iconSize }} src={icon} />
      <Text
        primaryTheme={primaryTheme}
        style={{ fontSize: textSize, fontWeight: textWeight }}
      >
        {text}
      </Text>
    </Container>
  )
}

export { TextWithIcon }
