import React, { useRef, useState } from 'react'

import { useOnClickOutside } from '../../../hooks'

import { CommunityAccordion } from './CommunityAccordion'

import ArrowRightIcon from 'assets/arrowRight.svg'
import { styled, s, smaller } from 'lib/styled'
import { content } from 'product.config'

const HamburgerContainer = styled.div(
  s('relative flex w-3/4', {
    userSelect: 'none',
  })
)

const Menu = styled.div(({ isMobileMenuOpen }) =>
  s('absolute flex flex-column w-full bg-white mt-0 p-4 justify-start z-5 h-a', {
    left: 0,
    height: '100vh',
    transition: 'transform 300ms ease-in-out',
    transform: isMobileMenuOpen ? 'translateX(0)' : 'translateX(-110%)',
  })
)

const MenuItem = styled.div(
  s('text-black text-base font-normal mx-3 mb-0 flex justify-between py-4'),
  { whiteSpace: 'nowrap', cursor: 'pointer' },
  smaller('md')(s('text-sm'))
)

const ArrowIcon = styled.img(({ isAccordionOpened }) =>
  s('', {
    transform: isAccordionOpened ? 'rotateZ(90deg)' : 'rotateZ(0)',
  })
)

function HamburgerMenu({
  onDismiss,
  dismissWhitelist = [],
  toHomePage,
  toProviderLandingPage,
  isMobileMenuOpen,
}) {
  const [isAccordionOpened, setIsAccordionOpened] = useState(false)

  const ref = useRef()
  useOnClickOutside(ref, dismissWhitelist, onDismiss)

  return (
    <HamburgerContainer ref={ref}>
      <Menu isMobileMenuOpen={isMobileMenuOpen}>
        <MenuItem onClick={toHomePage}> Home </MenuItem>
        <MenuItem>{content.appName} App</MenuItem>
        <MenuItem onClick={() => setIsAccordionOpened(!isAccordionOpened)}>
          Community
          <ArrowIcon src={ArrowRightIcon} isAccordionOpened={isAccordionOpened} />
        </MenuItem>

        <CommunityAccordion
          isOpened={isAccordionOpened}
          toProviderLandingPage={toProviderLandingPage}
        />
      </Menu>
    </HamburgerContainer>
  )
}

export { HamburgerMenu }
