import { path, pathOr, prop } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { listingModule } from './module'

import { REQUEST_STATUS } from 'utils/constants'

const { state$, effectState$ } = listingModule

const selectedListing$ = select(state$, (state) => prop('selectedListing', state))

const fetchListingByIdStatus$ = select(
  selectedListing$,
  effectState$,
  (selectedListing, effectState) =>
    path(['fetchListingById', path(['id'])(selectedListing), 'status'])(effectState)
)

const isFetchingListingById$ = select(
  fetchListingByIdStatus$,
  (status) => status === REQUEST_STATUS.PENDING
)

const featuredListings$ = select(state$, prop('featuredListings'))

const isFetchingFeaturedListings$ = select(effectState$, (effectState) => {
  const fetchFeaturedListingsStatus = pathOr(
    REQUEST_STATUS.PENDING,
    ['fetchFeaturedListings', 'status'],
    effectState
  )
  return fetchFeaturedListingsStatus === REQUEST_STATUS.PENDING
})

const listings$ = select(state$, (state) => prop('listings', state))

const isFetchingListings$ = select(effectState$, (effectState) => {
  const fetchListingsStatus = pathOr(
    REQUEST_STATUS.PENDING,
    ['fetchListings', 'status'],
    effectState
  )
  return fetchListingsStatus === REQUEST_STATUS.PENDING
})

export {
  selectedListing$,
  isFetchingListingById$,
  featuredListings$,
  listings$,
  isFetchingFeaturedListings$,
  isFetchingListings$,
}
