import { createModule } from '@soltalabs/stateless'

const INITIAL_STATE = Object.freeze({
  isMainSearchBarVisible: true,
  isHeaderMainSearchBarVisible: false,
  isOnMobile: false,
  screenWidth: undefined,
  isMobileMenuOpen: false,
})

const updateSearchBarVisibility = (module) => async (_, visibility) => {
  module.setState({
    isMainSearchBarVisible: visibility,
  })
}

const updateHeaderMainSearchBarVisibility = (module) => async (_, visibility) => {
  module.setState({
    isHeaderMainSearchBarVisible: visibility,
  })
}

const updateScreenWidth = (module) => (_, screenWidth) => {
  module.setState({
    screenWidth,
  })
}

const setMobileMenuOpen = (module) => (_, isOpen) => {
  module.setState({
    isMobileMenuOpen: isOpen,
  })
}

const uiModule = createModule({
  name: 'ui',
  initialState: INITIAL_STATE,
  decorators: {
    updateSearchBarVisibility,
    updateHeaderMainSearchBarVisibility,
    updateScreenWidth,
    setMobileMenuOpen,
  },
})

export { uiModule }
