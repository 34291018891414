export const colors = {
  primary: '#943798',
  dark: {
    900: '#1051226',
  },
  grey: {
    100: '#F6F9FD',
    200: '#F5F5F5',
    300: '#A4B0C0',
    400: '#73839C',
    500: '#394A64',
    600: '#293854',
    700: '#192B46',
    800: '#112138',
    900: '#051226',
  },
  hoverGrey: 'rgba(5, 18, 38, 0.1)',
  info: '#B57FB8',
  error: '#FDECEA',
  warning: '#FFF4E5',
  success: '#EDF7ED',
}
