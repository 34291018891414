import React from 'react'

import { styled } from 'lib/styled'

const StyledH1 = styled.h1({
  margin: 'auto',
  position: 'relative',
  top: 200,
  width: 400,
  textAlign: 'center',
  fontSize: 50,
})
const ComingSoon = () => {
  return <StyledH1>Coming Soon</StyledH1>
}

export default ComingSoon
