import React from 'react'

import { styled, s } from 'lib/styled'
import { colors } from 'product.config'

const Card = styled.div(
  s('p-6 mb-4', {
    maxWidth: 500,
    height: 258,
    background: '#ECF9F7',
    '@media screen and (max-width: 1000px)': {
      width: '80%',
      marginBottom: '2rem',
    },
    borderRadius: '10px',
  })
)

const CardNum = styled.div(
  s('mb-4 inline-block rounded-full grid pl font-semibold text-xl'),
  {
    width: 42,
    height: 42,
    color: colors.grey[900],
    border: '1px solid black',
    placeItems: 'center',
  }
)

const CardTitle = styled.span(s('block text-2xl font-normal mb-4'), {
  lineHeight: '33.6px',
  color: colors.grey[900],
  '@media screen and (max-width: 1200px)': {
    fontSize: '1.2rem',
  },
})

const CardDescription = styled.p(s('text-sm leading-normal font-normal'), {
  lineHeight: '20px',
  color: colors.grey[700],
  '@media screen and (max-width: 1200px)': {
    fontSize: '.8rem',
  },
})

function GetStartCard({ title, description, cardNum }) {
  return (
    <Card>
      <CardNum>{cardNum}</CardNum>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </Card>
  )
}

export { GetStartCard }
