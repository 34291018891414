import React from 'react'

import getStartCardData from '../layoutData/getStartCardData.json'

import { GetStartCard } from './GetStartCard'
import { SectionTitleBlock } from './SectionTitleBlock'

import { styled, s } from 'lib/styled'

const Container = styled.div(s('mb-24'))

const GetStartCardsContainer = styled.div(s('flex justify-center'), {
  '& > div:not(:last-child)': {
    marginRight: '2rem',
  },
  '@media screen and (max-width: 1000px)': {
    '& > div:not(:last-child)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    flexDirection: 'column',
    alignItems: 'center',
  },
})

const getCards = (getStartCardData) =>
  getStartCardData.map(({ title, description }, idx) => (
    <GetStartCard key={idx} title={title} description={description} cardNum={idx + 1} />
  ))

function GetStartSection() {
  return (
    <Container>
      <SectionTitleBlock title="How to get started" />

      <GetStartCardsContainer>{getCards(getStartCardData)}</GetStartCardsContainer>
    </Container>
  )
}

export { GetStartSection }
