import { isNotNil, includes } from '@soltalabs/ramda-extra'

const createUrlSearchParams = (params) => {
  const paramAllowedOnUrl = [
    'query',
    'sortBy',
    'sortOrder',
    'lat',
    'lng',
    'limit',
    'page',
    'query',
  ]

  const searchParams = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    if (isNotNil(value) && includes(key, paramAllowedOnUrl)) {
      searchParams.append(key, value)
    }
  })

  return searchParams
}

export { createUrlSearchParams }
