import { isNilOrEmpty } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import React from 'react'

import { shortenString } from '../../../utils/shortenString'

import { styled, s } from 'lib/styled'
import { screenWidth$ } from 'modules/ui'

const Container = styled.div(s('flex justify-between items-center ml-2'))

const SearchTerm = styled.span(s('font-semibold text-sm'))

const Divider = styled.div(
  s('border-0 border-r-1 border-solid h-2 mx-4', {
    borderColor: '#DEE5EF',
    opacity: 0.8,
  })
)

const ConnectSearchTermIndicator = connect(() => ({
  screenWidth: screenWidth$,
}))(SearchTermIndicator)

function SearchTermIndicator({ keyword = 'Any', location = 'Nearby', screenWidth }) {
  const showLocationTerm = screenWidth > 1030

  return (
    <Container>
      <SearchTerm>{handleEmptyString(keyword, 'No Keyword')}</SearchTerm>
      {showLocationTerm && (
        <>
          <Divider />
          <SearchTerm>
            {handleEmptyString(shortenString(splitLocation(location), 22), 'Nearby')}
          </SearchTerm>
        </>
      )}
    </Container>
  )
}

const handleEmptyString = (str, defaultStr) => {
  return isNilOrEmpty(str) ? defaultStr : str
}

const splitLocation = (location) => {
  const locArr = location.split(',')
  const country = locArr[0]
  let area = locArr[1] || ''

  if (area !== '') {
    area = `, ${area}`
  }

  return `${country}${area}`
}

export { ConnectSearchTermIndicator as SearchTermIndicator }
