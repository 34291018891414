import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'

import { ProviderLandingPage } from 'components/providerLanding'
import { styled, s } from 'lib/styled'
import { uiModule } from 'modules/ui'
import { RouteWithHeaders } from 'routers/RouteWithHeaders'

import 'react-bnb-gallery/dist/style.css'
import 'video-react/dist/video-react.css'

import 'styles/index.css'

const Container = styled.div(s('relative'), { overflow: 'hidden' })

function App() {
  useEffect(() => {
    const setWidth = () => {
      return uiModule.updateScreenWidth(null, window.innerWidth)
    }
    setWidth()
    window.addEventListener('resize', () => setWidth())
  })

  return (
    <Container className="App">
      <Switch>
        <Route path="/providerlanding" component={ProviderLandingPage} />
        <Route path="/" component={RouteWithHeaders} />
      </Switch>
    </Container>
  )
}

export default App
