import { isNotNil } from '@soltalabs/ramda-extra'
import React from 'react'

import { styled, s } from 'lib/styled'
import { colors } from 'product.config'

const TextContainer = styled.div(({ withMarginBottom }) =>
  s('', {
    maxWidth: '950px',
    marginBottom: withMarginBottom ? 100 : 50,
    '@media screen and (max-width: 1000px)': {
      maxWidth: '550px',
    },
    '@media screen and (max-width: 780px)': {
      textAlign: 'center',
      maxWidth: '950px',
    },
  })
)

const SectionTitle = styled.h1(s('text-4xl tracking-wide font-semibold'), {
  lineHeight: '56px',
  color: colors.primary,
  '@media screen and (max-width: 1000px)': {
    fontSize: '1.8rem',
  },
  '@media screen and (max-width: 520px)': {
    fontSize: '1.5rem',
  },
})

const SectionIntro = styled.p(s('text-lg font-normal'), {
  lineHeight: '35px',
  '@media screen and (max-width: 1000px)': {
    fontSize: '.9rem',
    lineHeight: '28px',
  },
  '@media screen and (max-width: 780px)': {
    fontSize: '1.1rem',
    lineHeight: '32px',
  },
  '@media screen and (max-width: 520px)': {
    lineHeight: '25px',
    width: '80%',
    margin: '0 auto',
    fontSize: '.9rem',
  },
})

function SectionTitleBlock({ title, intro, ...restProps }) {
  return (
    <TextContainer withMarginBottom={isNotNil(intro)} {...restProps}>
      <SectionTitle>{title}</SectionTitle>
      <SectionIntro>{intro}</SectionIntro>
    </TextContainer>
  )
}

export { SectionTitleBlock }
