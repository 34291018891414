import { createModule } from '@soltalabs/stateless'

import { CONFIG } from '../../config'

import { ListingService } from './service'

const INITIAL_STATE = Object.freeze({
  featuredListings: [],
  listings: [],
  selectedListing: null,
})

const fetchListingById = (module) => async (id, listingSource, location) => {
  let listing

  module.setState({ selectedListing: null })

  try {
    listing = await ListingService.get(id, listingSource, location)
  } catch (error) {
    throw new Error('There was an error loading this event. Try again in a little bit.')
  }

  module.setState({
    selectedListing: listing,
  })
}

const fetchListings = (module) => async (_, searchParams, location) => {
  const listings = await ListingService.getListings({
    ...location,
    ...searchParams,
  })

  module.setState({
    listings: listings.items,
    searchParams: {
      ...location,
      ...searchParams,
    },
  })
}

const fetchFeaturedListings = (module) => async (_, location) => {
  const listings = await ListingService.getListings({
    ...location,
    provider: CONFIG.PROVIDER.ID,
  })

  module.setState({
    featuredListings: listings.items,
  })
}

const listingModule = createModule({
  name: 'listing',
  initialState: INITIAL_STATE,
  decorators: {
    fetchListingById,
    fetchListings,
    fetchFeaturedListings,
  },
})

export { listingModule }
