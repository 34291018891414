import { connect } from '@soltalabs/stateless'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { Footer } from '../common'

import { BulaToHelpSection } from './providerLandingSections/BulaToHelpSection'
import { CaterListSection } from './providerLandingSections/CaterListSection'
import { CtaSection } from './providerLandingSections/CtaSection'
import { GetStartSection } from './providerLandingSections/GetStartSection'
import { HeroTextBlock } from './providerLandingSections/HeroTextBlock'

import { ReactComponent as ArrowUp } from 'assets/arrowUp.svg'
import LogoImg from 'assets/LogoImg.png'
import HeroImg from 'assets/providerLandingHero.png'
import { ReactComponent as WavyCorner } from 'assets/wavyCorner.svg'
import { styled, s } from 'lib/styled'
import { isOnMobile$ } from 'modules/ui'
import { colors } from 'product.config'
import { STYLE_CONFIG } from 'styles/config'

const Container = styled.div(
  s('px-16 pb-4 ml-a mr-a', {
    '@media screen and (max-width: 520px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    maxWidth: STYLE_CONFIG.MAX_WIDTH,
  })
)

const Logo = styled.img(s('p-1 mr-1 z-5 w-12', { cursor: 'pointer' }))

const NavContainer = styled.div(
  s('relative py-4 h-6 z-5 w-full', {
    '@media screen and (max-width: 780px)': {
      marginBottom: '4rem',
    },
    '@media screen and (max-width: 520px)': {
      paddingLeft: 16,
    },
  })
)

const WavyCornerContainer = styled.div(s('absolute'), {
  top: 0,
  right: 0,
  zIndex: -999,
  '@media screen and (max-width: 780px)': {
    display: 'none',
  },
  '@media screen and (max-width: 1400px)': {
    width: '58%',
  },
})

const HeroImgContainer = styled.div(s('rounded-lg w-2/5 p-0'), {
  overflow: 'hidden',
  '@media screen and (max-width: 780px)': {
    width: '100%',
  },
  '@media screen and (max-width: 520px)': {
    width: '80%',
  },
})

const LandingHeroContainer = styled.div(s('flex items-center justify-between mb-32'), {
  '@media screen and (max-width: 780px)': {
    flexDirection: 'column',
  },
})

const BackToTopBtn = styled.button(s('block border-0 bg-none flex items-center ml-a'), {
  cursor: 'pointer',
})

const BackToTopText = styled.span(s('font-semibold mr-2', { color: colors.primary }))

const ConnetedProviderLandingPage = connect(() => ({
  isOnMobile: isOnMobile$,
}))(ProviderLandingPage)

function ProviderLandingPage({ isOnMobile }) {
  const history = useHistory()

  function toHomePage() {
    history.push('/')
  }

  function scrollToTop() {
    window.scrollTo(0, 0)
  }

  return (
    <>
      <WavyCornerContainer>
        <WavyCorner />
      </WavyCornerContainer>

      <Container>
        <NavContainer>
          <Logo
            onClick={toHomePage}
            src={LogoImg}
            alt="Bula - the place you can find activities for your holiday"
          />
        </NavContainer>

        <LandingHeroContainer>
          <HeroTextBlock />
          <HeroImgContainer>
            <img
              src={HeroImg}
              alt="Happy tourists that used Bula"
              style={{ padding: 0, width: '100%', background: 'transparent' }}
            />
          </HeroImgContainer>
        </LandingHeroContainer>

        <CaterListSection />

        <BulaToHelpSection />

        <GetStartSection />

        <CtaSection />

        {!isOnMobile && (
          <BackToTopBtn onClick={scrollToTop}>
            <BackToTopText>Back to top</BackToTopText> <ArrowUp />
          </BackToTopBtn>
        )}
      </Container>
      <Footer primaryTheme />
    </>
  )
}

export { ConnetedProviderLandingPage as ProviderLandingPage }
