import { createModule } from '@soltalabs/stateless'

import { ProviderService } from './service'

const INITIAL_STATE = Object.freeze({})

const contactProvider = () => async (providerId, subject, message) => {
  try {
    const messageData = { providerId, subject, message }
    await ProviderService.contactProvider(messageData)
  } catch (error) {
    throw new Error(
      'There was an error contacting the provider. Try again in a little bit.'
    )
  }
}

const providerModule = createModule({
  name: 'provider',
  initialState: INITIAL_STATE,
  decorators: {
    contactProvider,
  },
})

export { providerModule }
