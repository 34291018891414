import React from 'react'

import { Chip } from 'lib/components'
import { styled, s } from 'lib/styled'

const Container = styled.div(s('', { cursor: 'pointer' }))

const ChipsContainer = styled.div(s('flex flex-wrap'))

const Image = styled.img(s('w-full rounded-lg'), {
  height: '180px',
  objectFit: 'cover',
})
const Metadata = styled.p(s('text-sm font-normal m-0 pt-1'))
const Title = styled.p(s('text-l font-normal mb-2 pt-1'))

const getChips = (tags) =>
  tags.map((tag, i) => <Chip key={i} text={tag.toUpperCase()} />)

const Card = ({ title, subTitle, tags, imgSrc, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Image src={imgSrc} />
      <Metadata>{subTitle}</Metadata>
      <Title>{title}</Title>
      <ChipsContainer>{getChips(tags)}</ChipsContainer>
    </Container>
  )
}

export { Card }
