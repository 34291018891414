import { pick } from '@soltalabs/ramda-extra'

const getParamsFromUrl = (desiredParamsArr, searchParamsStr) => {
  // const searchParams = window.location.search
  const urlSearchParams = new URLSearchParams(searchParamsStr)
  const paramObj = urlSearchParamToObj(urlSearchParams)

  return pick(desiredParamsArr, paramObj)
}

const urlSearchParamToObj = (urlSearchParams) => {
  const paramObj = {}

  // iterate over all keys
  for (const key of urlSearchParams.keys()) {
    if (urlSearchParams.getAll(key).length > 1) {
      paramObj[key] = urlSearchParams.getAll(key)
    } else if (urlSearchParams.get(key) !== 'undefined') {
      paramObj[key] = urlSearchParams.get(key)
    }
  }

  return paramObj
}

export { getParamsFromUrl }
