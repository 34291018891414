import { prop, pathOr } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { searchModule } from './module'

import { REQUEST_STATUS } from 'utils/constants'

const { state$, effectState$ } = searchModule

const autoCompletions$ = select(state$, (state) => prop('autoCompletions', state))
const searchSuggestions$ = select(state$, (state) => prop('searchSuggestions', state))
const searchResults$ = select(state$, (state) => prop('searchResults', state))
const resultsCount$ = select(state$, (state) => prop('resultsCount', state))
const searchResultsPagination$ = select(state$, (state) =>
  prop('searchResultsPagination', state)
)
const location$ = select(state$, (state) => prop('location', state))
const searchParams$ = select(state$, (state) => prop('searchParams', state))
const locationAddress$ = select(state$, (state) => prop('locationAddress', state))
const isSearchStateLost$ = select(state$, (state) => prop('isSearchStateLost', state))

const isSearchingListings$ = select(effectState$, (effectState) => {
  const searchListingsStatus = pathOr(
    REQUEST_STATUS.PENDING,
    ['searchListings', 'status'],
    effectState
  )

  return searchListingsStatus === REQUEST_STATUS.PENDING
})

export {
  autoCompletions$,
  searchSuggestions$,
  searchResults$,
  resultsCount$,
  location$,
  searchParams$,
  locationAddress$,
  isSearchingListings$,
  isSearchStateLost$,
  searchResultsPagination$,
}
