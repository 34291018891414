import React from 'react'

import { styled, s } from 'lib/styled'
import { colors } from 'product.config'

const Container = styled.div(({ color }) =>
  s('w-1/4 p-4 absolute rounded-md text-white font-semibold', {
    backgroundColor: color,
  })
)
const colorMap = {
  error: colors.error,
  warning: colors.warning,
  info: colors.info,
  success: colors.success,
}

export const Alert = ({ type, text, visible }) => {
  if (!visible) return null

  return <Container color={colorMap[type]}>{text}</Container>
}
