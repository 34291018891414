import React from 'react'

import { LocationDetail } from './LocationDetail'

import { styled, s, smaller } from 'lib/styled'

const Container = styled.div(s('relative flex flex-column justify-between pb-6'))

const Header = styled.p(
  s('text-black font-semibold p-0 m-0', {
    fontSize: '28px',
  }),
  smaller('md')(s('text-xl'))
)

const formatLocationData = (locations, isListing) => {
  if (!isListing) {
    return [locations]
  }
  return locations
}

function ActivityLocations({ locations, isOnMobile, selectedActivity, isListing }) {
  const formattedLocations = formatLocationData(locations, isListing)
  return (
    <Container>
      <Header>Location Details</Header>
      <LocationDetail
        locations={formattedLocations}
        isListing={isListing}
        isOnMobile={isOnMobile}
        selectedActivity={selectedActivity}
      />
    </Container>
  )
}

export { ActivityLocations }
