import { isNilOrEmpty, prop } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import React, { useEffect, useCallback, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { useHistory } from 'react-router-dom'

import { SearchBar } from './search'

import Header from 'assets/homeHeader.jpg'
import { ListCard } from 'lib/components'
import { styled, s, smaller } from 'lib/styled'
import { currentLocation$ } from 'modules/location'
import { searchModule } from 'modules/search'
import { uiModule } from 'modules/ui'
import { STYLE_CONFIG } from 'styles/config'

const Container = styled.div(
  s('relative flex flex-column justify-center items-center'),
  smaller('sm')(s(''))
)

const ContentContainer = styled.div(
  s('relative w-full pt-12'),
  smaller('sm')(s('pl-5 pr-5')),
  ({ shouldRestrictListGutter }) =>
    shouldRestrictListGutter
      ? s('pl-0 pr-0', { maxWidth: STYLE_CONFIG.MAX_WIDTH })
      : s('')
)

const ImageAndSearchBarContainer = styled.div(s('w-full'))

const HeaderImage = styled.img(s('relative w-full h-32'), {
  objectFit: 'cover',
  borderRadius: 30,
})

const BodyContainer = styled.div(
  s('relative flex flex-column w-full justify-center items-center', {
    top: -50,
  })
)

const AllActivitiesContainer = styled.div(
  s('relative w-full pb-16 flex flex-column', { ':last-child': { paddingBottom: 0 } }),
  smaller('md')(s('pb-12'))
)

const ActivitiesListContainer = styled.div(s('relative flex flex-wrap'), {
  gap: '40px 20px',
})

const SearchBarContainer = styled.div(s('relative pb-0 mb-0', { top: -107 }))

const Title = styled.h2(
  s('text-black text-3xl font-normal mb-6 self-start'),
  smaller('md')(s('text-2xl'))
)

const ConnectedHomePage = connect(() => ({
  currentLocation: currentLocation$,
}))(HomePage)

function HomePage({ currentLocation }) {
  const [SearchResults, setSearchResults] = useState()
  const history = useHistory()

  const toListingDetailsPage = useCallback((id, sourceType) => {
    if (sourceType.toLowerCase() === 'products') {
      history.push(`/product/${id}`)
    } else {
      history.push(`/listing/${id}`)
    }
  })

  useEffect(() => {
    searchModule.resetSearchParams()
  }, [])

  useEffect(async () => {
    if (currentLocation) {
      setSearchResults(prop('paginatedResults', await searchModule.searchListings()))
    }
  }, [currentLocation])

  const title = 'All Activities'

  const getAllActivities = useCallback((searchResults, title) => {
    if (isNilOrEmpty(searchResults)) {
      return (
        <AllActivitiesContainer>
          <Title>{title}</Title>
          <p>Nothing to show</p>
        </AllActivitiesContainer>
      )
    }

    const activitiesList = searchResults.map(
      ({ _id, name, sourceType, location, address, price, distance }) => {
        return (
          <div key={_id}>
            <ListCard
              title={name}
              location={location}
              address={address.suburb}
              price={price}
              distance={distance}
              sourceType={sourceType}
              onClick={() => toListingDetailsPage(_id, sourceType)}
            />
          </div>
        )
      }
    )

    return (
      <AllActivitiesContainer>
        <Title>{title}</Title>
        <ActivitiesListContainer>{activitiesList}</ActivitiesListContainer>
      </AllActivitiesContainer>
    )
  })

  const updateSearchBarVisibility = (isVisible) => {
    uiModule.updateSearchBarVisibility(null, isVisible)

    if (isVisible) {
      uiModule.updateHeaderMainSearchBarVisibility(null, !isVisible)
    }
  }

  return (
    <Container>
      <ImageAndSearchBarContainer>
        <HeaderImage src={Header} />
        <SearchBarContainer>
          <InView onChange={updateSearchBarVisibility}>
            <SearchBar />
          </InView>
        </SearchBarContainer>
      </ImageAndSearchBarContainer>

      <BodyContainer>
        <ContentContainer>{getAllActivities(SearchResults, title)}</ContentContainer>
      </BodyContainer>
    </Container>
  )
}

export { ConnectedHomePage as HomePage }
