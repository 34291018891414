import React from 'react'

import caterListCardData from '../layoutData/caterListCardData.json'

import { CaterListCard } from './CaterListCard'
import { SectionTitleBlock } from './SectionTitleBlock'

import { styled, s } from 'lib/styled'

const CaterListContainer = styled.div(
  s('flex', {
    '& > div:not(:last-child)': {
      marginRight: '2rem',
    },
    '@media screen and (max-width: 1000px)': {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      '& > div:not(:last-child)': {
        margin: '0 0 4rem 0',
      },
    },
    '@media screen and (max-width: 520px)': {
      width: '80%',
      margin: '0 auto',
    },
  })
)

const getCaterListCards = (caterListCardData) =>
  caterListCardData.map(({ title, intro, imgSrc, placement }, idx) => (
    <CaterListCard
      key={idx}
      title={title}
      intro={intro}
      imgSrc={imgSrc}
      placement={placement}
    />
  ))

function CaterListSection() {
  return (
    <div>
      <SectionTitleBlock
        title="Create your listings"
        intro="List any type of activities with Bula: adventures, backpacking, couples, cruising, diving, family, golf, luxury, nature, sailing, sustainability, snorkerling, surfing, volunteering, and weddings -  everyone is welcome at Bula."
      />

      <CaterListContainer>{getCaterListCards(caterListCardData)}</CaterListContainer>
    </div>
  )
}

export { CaterListSection }
