import React from 'react'

import { styled, s } from 'lib/styled'

const Container = styled.div(
  s('flex justify-start flex-wrap content-start py-1 px-0 w-full'),
  {
    marginTop: 24,
    height: '100%',
    gap: '24px 20px',
  }
)

const ResultListContainer = ({ children }) => {
  return <Container>{children}</Container>
}

export default ResultListContainer
