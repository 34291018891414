import React from 'react'

import { styled, s } from 'lib/styled'
import { colors } from 'product.config'

const Container = styled.div(
  s('flex items-center font-semibold text-sm my-2 pr-12 px-2', {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    ':hover': {
      backgroundColor: colors.hoverGrey,
    },
  })
)

const Line = styled.div(
  s('', { width: '100%', height: '1px', backgroundColor: colors.grey[200] })
)

function CommunityDropdownItem({ text, hasBottomBorder = true, ...props }) {
  return (
    <>
      <Container {...props}>{text}</Container>
      {hasBottomBorder && <Line />}
    </>
  )
}

export { CommunityDropdownItem }
