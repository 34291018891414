import React from 'react'

import { CONFIG } from '../../../config'

import { styled, s, smaller } from 'lib/styled'

const Container = styled.div(({ isOpened }) =>
  s('pl-8', {
    overflow: 'hidden',
    maxHeight: isOpened ? '200px' : '0px',
    transition: 'max-height 250ms ease-in-out',
  })
)

const Item = styled.a(
  s('block p-4 text-black text-base font-normal'),
  {
    '&:hover': {
      color: 'black',
    },
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  smaller('md')(s('text-sm'))
)

function CommunityAccordion({ isOpened, toProviderLandingPage }) {
  return (
    <Container isOpened={isOpened}>
      <Item href={`${CONFIG.WIX.URL}blog`}>Blogs</Item>
      <Item href={`${CONFIG.WIX.URL}families`}>Bula benefits</Item>
      <Item onClick={toProviderLandingPage}>Become a creator</Item>
    </Container>
  )
}

export { CommunityAccordion }
