import React from 'react'

import { styled, s } from 'lib/styled'
import { colors } from 'product.config'

const getActivitySubtitle = (address, distance) => {
  const FocalText = styled.span(s('font-semibold text-sm'), { color: colors.dark[900] })

  const Text = styled.span(s('text-sm'), { color: colors.dark[900] })

  return (
    <>
      <Text> {address?.suburb || ''}</Text>
      <FocalText> ({distance}km)</FocalText>
    </>
  )
}

export { getActivitySubtitle }
