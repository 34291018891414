import React from 'react'
import { useHistory } from 'react-router-dom'

import { AppStoreBadge } from '../AppStoreBadge'
import { PlayStoreBadge } from '../PlayStoreBadge'

import { BottomContent } from './BottomContent'

import CopyrightIcon from 'assets/copyright.svg'
import CopyrightIconWhite from 'assets/copyright_white.svg'
import HeartIcon from 'assets/heart.svg'
import HeartIconWhite from 'assets/heart_white.svg'
import { TextWithIcon } from 'lib/components'
import { Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { colors } from 'product.config'
// eslint-disable-next-line complexity
const Container = styled.div(({ shouldBlur, primaryTheme }) =>
  s('relative w-full py-8', {
    backgroundColor: shouldBlur ? '#051226' : '#FAFAFB',
    background: primaryTheme ? colors.primary : '#FAFAFB',
    bottom: 0,
    paddingLeft: '5%',
    paddingRight: '5%',
    opacity: shouldBlur ? 0.5 : 1,
    '& p, & a': {
      color: primaryTheme ? colors.grey['100'] : 'black',
      marginBottom: '12px',
      cursor: 'pointer',
    },
    '& span': {
      color: primaryTheme ? 'white' : 'black',
    },
    '@media screen and (max-width: 580px)': {
      paddingLeft: 32,
      paddingRight: 32,
    },
  })
)

const UpperContainer = styled.div(
  s(
    'w-full relative flex flex-row items-start border-0 border-solid border-b-1 border-grey-light mb-8 pb-16'
  ),
  {
    '@media screen and (max-width: 1200px)': {
      paddingBottom: 0,
    },
    '@media screen and (max-width: 580px)': {
      flexDirection: 'column',
    },
  }
)

const BottomContainer = styled.div(s('w-full relative flex flex-row justify-between'), {
  '@media screen and (max-width: 1200px)': {
    flexDirection: 'column-reverse',
    height: '150px',
  },
})

const Menu = styled.div(s('flex flex-column justify-between pr-4'), {
  '@media screen and (max-width: 1200px)': {
    flexDirection: 'column',
    '& > div': {
      marginBottom: '2rem',
    },
  },
})

const AppContainer = styled.div(s('relative flex flex-column pl-48'), {
  '@media screen and (max-width: 1200px)': {
    paddingLeft: 0,
  },
  '@media screen and (max-width: 580px)': {
    paddingLeft: 0,
    order: -1,
    marginBottom: '2rem',
  },
})

const BadgeContainer = styled.div(s('flex flex-row w-full'))

const Image = styled.img({ cursor: 'pointer' })

const Title = styled.p(s('text-base font-semibold mb-4', { cursor: 'auto !important' }))

const FooterLink = styled.a(s('block'))

const renderAsset = (oriAsset, themedAsset, primaryTheme) => {
  return primaryTheme ? themedAsset : oriAsset
}

function Footer({ shouldBlur = false, primaryTheme = false }) {
  const history = useHistory()

  return (
    <Container shouldBlur={shouldBlur} primaryTheme={primaryTheme}>
      <div style={s('ml-a mr-a')}>
        <UpperContainer>
          <Menu>
            <Col>
              <Title> Become a provider </Title>
              <FooterLink onClick={() => history.push('/providerlanding')}>
                Become a provider
              </FooterLink>
            </Col>
          </Menu>

          <AppContainer>
            <Title>Download our app</Title>
            <p style={{ cursor: 'auto' }}>Book activities in Fiji anywhere, anytime.</p>
            <BadgeContainer>
              <AppStoreBadge />
              <PlayStoreBadge />
            </BadgeContainer>
          </AppContainer>
        </UpperContainer>

        <BottomContainer>
          <BottomContent
            CopyRightBlock={() => (
              <TextWithIcon
                text="2022 Bula. All rights reserved"
                icon={renderAsset(CopyrightIcon, CopyrightIconWhite, primaryTheme)}
                textSize={14}
                iconSize={14}
              />
            )}
            LoveText={() => (
              <p>
                Made with
                {<Image src={renderAsset(HeartIcon, HeartIconWhite, primaryTheme)} />}
                from New Zealand
              </p>
            )}
          />
        </BottomContainer>
      </div>
    </Container>
  )
}

export { Footer }
