import { path } from '@soltalabs/ramda-extra'
import { createModule } from '@soltalabs/stateless'

import { LOCATION } from 'utils/constants'

const INITIAL_STATE = Object.freeze({
  currentLocation: LOCATION.DEFAULT_LOCATION,
})

const fetchCurrentLocation = (module) => () => {
  return navigator.geolocation.getCurrentPosition(
    (position) => {
      const location = {
        lat: path(['coords', 'latitude'])(position),
        lng: path(['coords', 'longitude'])(position),
      }

      module.setState({
        currentLocation: location,
      })
    },
    () => {
      module.setState({
        currentLocation: LOCATION.DEFAULT_LOCATION,
      })
    }
  )
}

const setCurrentLocation = (module) => (_, location) => {
  module.setState({
    currentLocation: location,
  })
}

const locationModule = createModule({
  name: 'location',
  initialState: INITIAL_STATE,
  decorators: {
    fetchCurrentLocation,
    setCurrentLocation,
  },
})

export { locationModule }
