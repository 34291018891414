import { connect } from '@soltalabs/stateless'
import React, { useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { CommunityDropdown } from './CommunityDropdown'
import { HamburgerMenu } from './HamburgerMenu'
import { HeaderMainSearchBar } from './HeaderMainSearchBar'
import { SearchTermIndicator } from './SearchTermIndicator'

import HamburgerIcon from 'assets/hamburgerMenu.svg'
import LogoImg from 'assets/LogoImg.png'
import Magifier from 'assets/magnifier.svg'
import { styled, s, smaller } from 'lib/styled'
import { searchParams$, locationAddress$ } from 'modules/search'
import {
  isHeaderMainSearchBarVisible$,
  uiModule,
  isHeaderSimpleSearchBarVisible$,
  isOnMobile$,
  isMobileMenuOpen$,
} from 'modules/ui'

const Container = styled.div(({ isAtHomePage }) =>
  s('sticky flex flex-column justify-between items-center z-5 w-full', {
    paddingLeft: '4%',
    paddingRight: '4%',
    top: 0,
    background: 'white',
    boxShadow: isAtHomePage ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.15)',
  })
)

const TopContainer = styled.div(
  s('relative flex flex-row w-full pt-4 pb-6 justify-between', {})
)

const LeftContainer = styled.div(s('relative flex w-1/2'))

const Menu = styled.div(s('flex items-center flex-row justify-end'))

const Title = styled.h1(
  s('text-black text-base font-normal mx-3 mb-0'),
  { whiteSpace: 'nowrap', cursor: 'pointer' },
  smaller('md')(s('text-sm'))
)

const CommunityBtnContainer = styled.div(s('relative', { cursor: 'pointer' }))

const SimpleSearchBar = styled.div(
  s(
    'relative flex flex-row bg-white self-center rounded-lg items-center pl-2 ml-4 justify-between'
  ),
  {
    border: '0.5px solid #DEE5EF',
    whiteSpace: 'nowrap',
  }
)

const SearchText = styled.span(
  s('text-grey text-xl font-normal border-0 mr-16'),
  smaller('md')(s('text-sm'))
)

const SearchButton = styled.img(s('ml-4 mr-2 my-1'), {
  cursor: 'pointer',
  height: '39px',
  width: '36px',
})

const MobileContainer = styled.div(s('relative flex flex-column w-full py-4'))

const MobileTopContainer = styled.div(
  s('relative flex flex-row w-full justify-between px-5')
)

const Logo = styled.img(s('z-5', { cursor: 'pointer', height: '45px' }))

const MobileHamburger = styled.img(s('p-1 z-5', { cursor: 'pointer' }))

const MagifierLogo = styled.img(({ showMagifier = false }) =>
  s('block', { cursor: 'pointer', opacity: showMagifier ? '1' : '0' })
)

const ConnectedHeader = connect(() => ({
  isHeaderMainSearchBarVisible: isHeaderMainSearchBarVisible$,
  isHeaderSimpleSearchBarVisible: isHeaderSimpleSearchBarVisible$,
  isOnMobile: isOnMobile$,
  isMobileMenuOpen: isMobileMenuOpen$,
  searchParams: searchParams$,
  locationAddress: locationAddress$,
}))(Header)

function Header({
  isHeaderMainSearchBarVisible,
  isHeaderSimpleSearchBarVisible,
  isOnMobile,
  isMobileMenuOpen,
  searchParams,
  locationAddress,
  isAtHomePage,
}) {
  const history = useHistory()
  const location = useLocation()
  const hamburgerBtnRef = useRef()
  const communityBtnRef = useRef()

  const showSearchTermIndicator = location.pathname !== '/'

  const [isDropdownOpened, setIsDropdownOpened] = useState(false)
  function toProviderLandingPage() {
    uiModule.setMobileMenuOpen(null, false)
    history.push('/providerlanding')
  }

  function toHomePage() {
    uiModule.setMobileMenuOpen(null, false)
    history.push('/')
  }

  const handleSimpleSearchBarOnClick = () => {
    uiModule.updateHeaderMainSearchBarVisibility(null, true)
  }

  const handleHamburgerOnClick = () => {
    uiModule.setMobileMenuOpen(null, !isMobileMenuOpen)
  }

  const generateHeader = () => {
    if (isOnMobile) {
      return (
        <MobileContainer>
          <MobileTopContainer>
            <MobileHamburger
              onClick={handleHamburgerOnClick}
              src={HamburgerIcon}
              ref={hamburgerBtnRef}
            />

            <Logo onClick={toHomePage} src={LogoImg} />

            <MagifierLogo
              onClick={handleSimpleSearchBarOnClick}
              src={Magifier}
              showMagifier={isHeaderSimpleSearchBarVisible}
            />
          </MobileTopContainer>

          <HamburgerMenu
            isMobileMenuOpen={isMobileMenuOpen}
            dismissWhitelist={[hamburgerBtnRef?.current]}
            onDismiss={() => uiModule.setMobileMenuOpen(null, false)}
            toHomePage={toHomePage}
            toProviderLandingPage={toProviderLandingPage}
          />
        </MobileContainer>
      )
    }

    return (
      <TopContainer>
        <LeftContainer>
          <Logo onClick={toHomePage} src={LogoImg} />
          <SimpleSearchBar
            hidden={!isHeaderSimpleSearchBarVisible}
            onClick={handleSimpleSearchBarOnClick}
          >
            {showSearchTermIndicator ? (
              <SearchTermIndicator
                keyword={searchParams.query}
                location={locationAddress}
              />
            ) : (
              <SearchText>Search</SearchText>
            )}
            <SearchButton src={Magifier} />
          </SimpleSearchBar>
        </LeftContainer>

        <Menu>
          {/* <Title>{content.appName} App</Title> */}
          <CommunityBtnContainer>
            <Title
              ref={communityBtnRef}
              onClick={() => setIsDropdownOpened(!isDropdownOpened)}
            >
              Community
            </Title>
            <CommunityDropdown
              onDismiss={() => setIsDropdownOpened(false)}
              dismissWhitelist={[communityBtnRef?.current]}
              visible={isDropdownOpened}
              toProviderLandingPage={toProviderLandingPage}
            />
          </CommunityBtnContainer>
        </Menu>
      </TopContainer>
    )
  }

  return (
    <Container isAtHomePage={isAtHomePage}>
      {generateHeader()}

      {isHeaderMainSearchBarVisible && (
        <HeaderMainSearchBar
          onDismiss={() => uiModule.updateHeaderMainSearchBarVisibility(null, false)}
        />
      )}
    </Container>
  )
}

export { ConnectedHeader as Header }
