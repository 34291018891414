import React from 'react'

import { styled, s, smaller } from 'lib/styled'
import { STYLE_CONFIG } from 'styles/config'

const Container = styled.div(
  s(
    'relative flex flex-column justify-between border-0 border-solid border-b-1 border-grey-light pt-6'
  ),
  {
    overflow: 'hidden',
    maxWidth: STYLE_CONFIG.MAX_WIDTH,
  }
)

const TitleContainer = styled.div(s('relative flex flex-row justify-start p-4 pl-0'))

const AboutContainer = styled.div(s('relative flex flex-row justify-start p-4 pl-0'))

const Header = styled.p(
  s('text-black font-semibold p-0 m-0', {
    fontSize: '28px',
  }),
  smaller('md')(s('text-xl'))
)

const Title = styled.p(
  s('text-black text-xl font-semibold p-0 m-0'),
  smaller('md')(s('text-sm'))
)

const Subtitle = styled.p(s('text-grey-dark font-normal p-0 m-0 text-sm'), {})

const Description = styled.p(s('text-black text-m font-normal m-0 p-4 pl-10===='))

const Logo = styled.img(
  s('border-2 border-white mr-4', {
    width: 76,
    height: 76,
    borderRadius: 55,
    filter: 'drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.07))',
  })
)

function ProviderDetails({ provider }) {
  return (
    <Container>
      <Header>About the provider</Header>
      <AboutContainer>
        <TitleContainer>
          <Logo src={provider.logo} />
          <div>
            <Title>{provider.name}</Title>
            <Subtitle>{provider.address.formattedAddress}</Subtitle>
          </div>
        </TitleContainer>
        <Description>{provider.businessSummary}</Description>
      </AboutContainer>
    </Container>
  )
}

export { ProviderDetails }
