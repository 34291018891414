import React from 'react'

import { styled, s } from 'lib/styled'

const ButtonBase = styled.button(({ color, textColor }) =>
  s(`h-3 px-4 bg-none border-1 border-solid rounded-lg font-bold ${textColor}`, {
    backgroundColor: color,
    borderColor: color || '',
    ':hover': {
      cursor: 'pointer',
    },
  })
)

const Button = ({ text, onClick, style, color, inverse }) => {
  const textColor = inverse ? 'text-white' : 'text-near-black'
  return (
    <ButtonBase onClick={onClick} style={style} color={color} textColor={textColor}>
      {text}
    </ButtonBase>
  )
}

export { Button }
