import { and, difference, or, uniq } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import React, { useEffect, useReducer } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'

import { Footer, Header } from 'components/common'
import { HomePage } from 'components/HomePage'
import { ListingDetailsPage } from 'components/listingDetails/ListingDetailsPage'
import { ProductDetailsPage } from 'components/productDetails/ProductDetailsPage'
import { SearchResults } from 'components/search'
import ComingSoon from 'components/search/ComingSoon'
import SearchContext from 'contexts/SearchHistoryContext'
import { styled, s } from 'lib/styled'
import { locationModule, currentLocation$ } from 'modules/location'
import {
  isHeaderMainSearchBarVisible$,
  isMobileMenuOpen$,
  isOnMobile$,
  uiModule,
} from 'modules/ui'
import { LOCATION } from 'utils/constants'

const Body = styled.div(() =>
  s('', {
    paddingLeft: '5%',
    paddingRight: '5%',
    minHeight: '100vh',
  })
)

const Container = styled.div(s('relative'), {
  overflow: 'hidden',
  margin: 'auto',
})

const Overlay = styled.div(
  s('fixed pin bg-black z-2', {
    opacity: 0.5,
  })
)

const handleOverlayOnClick = () => {
  uiModule.updateHeaderMainSearchBarVisibility(null, false)
  uiModule.setMobileMenuOpen(null, false)
}

const searchHistoryReducer = (state, action) => {
  switch (action.type) {
    case 'ADD':
      return uniq([...state, action.location])
    case 'DEL':
      return difference(state, action.location)
    default:
      return state
  }
}

const ConnectedRouteWithHeaders = connect(() => ({
  isHeaderMainSearchBarVisible: isHeaderMainSearchBarVisible$,
  isMobileMenuOpen: isMobileMenuOpen$,
  currentLocation: currentLocation$,
  isOnMobile: isOnMobile$,
}))(RouteWithHeaders)

function RouteWithHeaders({
  isHeaderMainSearchBarVisible,
  isMobileMenuOpen,
  currentLocation,
  isOnMobile,
}) {
  const location = useLocation()
  const isAtHomePage = location.pathname === '/'

  const [searchHistory, dispatch] = useReducer(searchHistoryReducer, [])

  const showOverlay = or(
    isHeaderMainSearchBarVisible,
    and(isMobileMenuOpen, isOnMobile)
  )

  useEffect(() => {
    window.scrollTo(0, 0)
    uiModule.updateHeaderMainSearchBarVisibility(null, false)
    if (isAtHomePage) {
      uiModule.updateSearchBarVisibility(null, true)
    } else {
      uiModule.updateSearchBarVisibility(null, false)
    }
  }, [location.pathname])

  useEffect(() => {
    locationModule.fetchCurrentLocation()
  }, [])

  useEffect(() => {
    const fetchLocationTimer = setTimeout(() => {
      if (!currentLocation) {
        locationModule.setCurrentLocation(null, LOCATION.DEFAULT_LOCATION)
      }
    }, LOCATION.FETCH_USER_LOCATION_TIMEOUT_IN_MS)

    return () => clearTimeout(fetchLocationTimer)
  }, [currentLocation])

  return (
    <Container className="App">
      <SearchContext.Provider value={[searchHistory, dispatch]}>
        <Header isAtHomePage={isAtHomePage} />
        <Body>
          <Switch>
            <Route path="/search" component={SearchResults} />
            <Route exact path="/listing/:id" component={ListingDetailsPage} />
            <Route exact path="/product/:id" component={ProductDetailsPage} />
            <Route path="/comingsoon" component={ComingSoon} />
            <Route path="/" component={HomePage} />
          </Switch>
        </Body>
        <Footer />
      </SearchContext.Provider>
      {showOverlay && <Overlay onClick={handleOverlayOnClick} />}
    </Container>
  )
}

export { ConnectedRouteWithHeaders as RouteWithHeaders }
