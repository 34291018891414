import { isNotNil } from '@soltalabs/ramda-extra'

import SearchApiEndpoint from './searchApiEndpoint'

import { http } from 'lib/http-client'

const SearchService = {
  async getAutoCompletions(query) {
    return http.get(`auto-completions/?type=listing-keywords&q=${query}`).json()
  },

  async getSearchSuggestions() {
    return http.get(`public/categories/suggested`).json()
  },

  async search({
    query,
    categories,
    type,
    price,
    from,
    to,
    distance = 100,
    lat,
    lng,
    page = 1,
    limit,
    sortBy,
    sortOrder = 1,
    keyword,
  }) {
    const params = {
      searchTerms: query,
      categories,
      type,
      price,
      from,
      to,
      distance,
      lat,
      lng,
      page,
      limit,
      sortBy,
      sortOrder,
      keyword,
    }
    const urlSearchParams = new URLSearchParams()

    Object.entries(params).forEach(([key, value]) => {
      if (isNotNil(value)) {
        urlSearchParams.append(key, value)
      }
    })

    const unifiedResults = await http
      .get(
        `${SearchApiEndpoint.GetUnifiedSearchResults}?${urlSearchParams.toString()}`,
        { timeout: 30000 }
      )
      .json()

    return unifiedResults
  },
}

export { SearchService }
