import React from 'react'

import { ReactComponent as NavArrowLeft } from 'assets/navArrowLeft.svg'
import { ReactComponent as NavArrowRight } from 'assets/navArrowRight.svg'
import { styled, s } from 'lib/styled'

const NavControlContainer = styled.div(
  s('flex flex-row ml-a content-center items-center font-semibold')
)

const NavButton = styled.button(
  s('bg-transparent border-transparent p-0 m-0 border-0 h-100 flex ', {
    cursor: 'pointer',
    ':disabled': {
      cursor: 'auto',
    },
  })
)

const NavDataDisplay = styled.span(s('ml-5 mr-5 font-semibold'))

function MapNav({ currentIndex, totalLocations, onNavClick }) {
  return (
    <NavControlContainer>
      <NavButton onClick={() => onNavClick(-1)} disabled={currentIndex <= 0}>
        <NavArrowLeft opacity={currentIndex <= 0 ? '50%' : '100%'} />
      </NavButton>
      <NavDataDisplay>{`${currentIndex + 1}/${totalLocations}`}</NavDataDisplay>
      <NavButton
        onClick={() => onNavClick(1)}
        disabled={currentIndex >= totalLocations - 1}
      >
        <NavArrowRight opacity={currentIndex >= totalLocations - 1 ? '50%' : '100%'} />
      </NavButton>
    </NavControlContainer>
  )
}

export { MapNav }
