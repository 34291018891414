import React from 'react'

import { styled, s } from 'lib/styled'
import { colors } from 'product.config'

const DefaultChip = styled.span(({ outline }) =>
  s('p-1 pl-2 pr-2 mr-1 rounded-md text-xs mb-2', {
    color: colors.grey[600],
    background: outline ? 'none' : colors.grey[200],
    border: outline ? `1px solid ${colors.grey[400]}` : '',
    whiteSpace: 'nowrap',
  })
)

const OutlinedChip = styled.span(
  s('text-sm py-3 px-4 mr-2 rounded-2xl border-1 border-solid border-grey-dark')
)

const CategoriesChip = styled.span(
  s('text-sm rounded-xl border-1 border-solid border-grey-dark'),
  { padding: '4px 10px' }
)

const SourceTypeChip = styled.span(s('p-2'), {
  display: 'inline-block',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '16px',
  letterSpacing: '-0.2200000286102295px',
  textAlign: 'left',
  marginBottom: '6px',
  backgroundColor: '#F6F9FD',
  borderRadius: '6px',
})

const chips = {
  default: DefaultChip,
  outlined: OutlinedChip,
  category: CategoriesChip,
  sourceType: SourceTypeChip,
}

const Chip = ({ text, variation, ...otherProps }) => {
  const TargetChip = chips[variation || 'default']
  const targetText = variation === 'default' ? text.toUpperCase() : text

  return <TargetChip {...otherProps}>{targetText}</TargetChip>
}

export { Chip }
