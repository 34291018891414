import { createModule } from '@soltalabs/stateless'

import { GoogleMapService } from './service'

const INITIAL_STATE = Object.freeze({
  locationSuggestions: [],
})

const fetchLocationSuggestions = (module) => async (query, coordinates) => {
  const results = await GoogleMapService.searchPlaces(query, coordinates)

  module.setState({
    locationSuggestions: results,
  })
}

const mapModule = createModule({
  name: 'search',
  initialState: INITIAL_STATE,
  decorators: { fetchLocationSuggestions },
})

export { mapModule }
