import { getEnvVariable } from 'utils/getEnvVariable'

const CONFIG = {
  API: {
    URL: getEnvVariable('API_URL'),
  },
  MAP_BOX: {
    TOKEN: getEnvVariable('MAP_BOX_ACCESS_TOKEN'),
    STYLE_URL: getEnvVariable('MAP_BOX_STYLE_URL'),
    URL: getEnvVariable('MAP_BOX_API_ENDPOINT'),
  },
  PROVIDER: {
    URL: getEnvVariable('PROVIDER_PORTAL_URL'),
    ID: getEnvVariable('PROVIDER_ID'),
  },
  WIX: {
    URL: getEnvVariable('WIX_URL'),
  },
}

export { CONFIG }
