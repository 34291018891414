import pThrottle from 'p-throttle'

const throttle = pThrottle({
  limit: 5,
  interval: 1000,
})

const service = new google.maps.places.AutocompleteService()

const GeocodingService = {
  forward: throttle((query, coordinateBias) => {
    const request = { input: query }

    if (coordinateBias) {
      const { lng, lat } = coordinateBias

      request.location = new google.maps.LatLng({ lat, lng })
      request.radius = 100000
    }

    return service.getPlacePredictions(request)
  }),
}

const GoogleMapService = {
  async searchPlaces(query, coordinateBias) {
    GeocodingService.forward.abort()

    const { predictions } = await GeocodingService.forward(query, coordinateBias)

    return predictions
  },
}

export { GoogleMapService }
