import { Pagination } from '@material-ui/lab'
import { path } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import 'mapbox-gl/dist/mapbox-gl.css'
import { Map } from '../../map'

import { checkIsLocationEmpty } from './utils'

import PlaceHolderImage from 'assets/MapPinPlaceHolder.png'
import { ActivityList } from 'components/search/searchResult/ActivityList'
import { styled, s } from 'lib/styled'
import { currentLocation$ } from 'modules/location'
import {
  searchModule,
  searchResults$,
  resultsCount$,
  isSearchingListings$,
} from 'modules/search'
import { getParamsFromUrl } from 'utils/url'

const FlexContainer = styled.div(s('flex w-full'), {
  maxHeight: '1385px',
  paddingTop: '78px',
  justifyContent: 'center',
})

const BlockContainer = styled.div(s('flex flex-column w-3/5'))

const Title = styled.div({
  width: '223px',
  height: '44px',
  fontWeight: '600',
  fontSize: '32px',
  lineHeight: '44px',
  letterSpacing: '-0.22px',
  color: '#051226',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
})

const MapView = styled.div(s('h-full w-2/5'), {
  '& canvas': { maxWidth: '100% !important' },
})

const MapContainer = styled.div({ backgroundColor: 'white' })

const ConnectedSearchResultsContainer = connect(() => ({
  currentLocation: currentLocation$,
  searchResults: searchResults$,
  resultsCount: resultsCount$,
  isSearchingListings: isSearchingListings$,
}))(SearchResultsContainer)

function SearchResultsContainer({
  currentLocation,
  searchResults,
  resultsCount,
  isSearchingListings,
}) {
  const urlLocation = useLocation()
  const history = useHistory()
  const [map, setMap] = useState()
  const [pagingLimit] = useState(15)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortByState, setSortByState] = useState('distance')
  const [sortOrderState, setOrderState] = useState(1)

  const PaginationContainer = styled.div({
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#394A64',
  })

  useEffect(async () => {
    searchWithCurrentUrlSearchParamsAndSetMap()
  }, [pagingLimit, currentPage, sortByState, sortOrderState])

  const StyledPagination = styled(Pagination)({
    '&:hover': {
      cursor: 'pointer',
    },
    '& .MuiPaginationItem-outlinedSecondary.Mui-selected': {
      color: '#FFFFFF',
      backgroundColor: '#89308d',
      border: 'none',
    },
    '& .MuiPaginationItem-outlinedSecondary': {
      backgroundColor: '#F6F9FD',
      border: 'none',
    },
  })

  const handlePaginationChagne = (event, page) => {
    setCurrentPage(page)
  }

  const paginator = useMemo(() => {
    if (searchResults.length === 0 || resultsCount === 0) {
      return <div />
    }
    const numberOfPages = Math.ceil(resultsCount / pagingLimit)
    return (
      <StyledPagination
        count={numberOfPages}
        variant="outlined"
        shape="rounded"
        color="secondary"
        page={currentPage}
        onChange={handlePaginationChagne}
      />
    )
  }, [currentPage, searchResults, resultsCount])

  return (
    <FlexContainer>
      <BlockContainer>
        <Title>Search Results</Title>
        <ActivityList
          currentLocation={currentLocation}
          searchResults={searchResults}
          isSearchingListings={isSearchingListings}
          setSortBy={setSortByState}
          setSortOrder={setOrderState}
        />
        <PaginationContainer>{paginator}</PaginationContainer>
      </BlockContainer>
      <MapView>{map ?? null}</MapView>
    </FlexContainer>
  )

  function searchWithCurrentUrlSearchParamsAndSetMap() {
    const { lat, lng, query } = getParamsFromUrl(
      ['lat', 'lng', 'sortBy', 'sortOrder', 'limit', 'page', 'query'],
      urlLocation.search
    )
    const searchParams = {
      lat,
      lng,
      sortBy: sortByState,
      sortOrder: sortOrderState,
      limit: pagingLimit,
      page: currentPage,
      query,
    }
    const locationParams = { lat, lng }
    const isLocationEmpty = checkIsLocationEmpty(locationParams)
    const validLocation = isLocationEmpty ? currentLocation : locationParams

    searchModule.setSearchParams(null, searchParams)
    searchModule.setLocation(null, validLocation)

    searchModule.searchListings().then((response) => {
      const results = response.paginatedResults
      const urlSearchparams = new URLSearchParams(searchParams, locationParams)
      history.replace({
        pathname: urlLocation.pathname,
        search: urlSearchparams.toString(),
      })
      const markerCoords = results.map(({ location }) => {
        return location.coordinates
      })

      const mapData = results.map((activity) => {
        return {
          address: activity.address.formattedAddress,
          title: activity.name,
          summary: activity.name,
          photo: path(['imageUrls', '0'], activity) ?? PlaceHolderImage,
          distance: Math.floor(activity.distance / 1000),
        }
      })

      setMap(
        <MapContainer id="Map">
          <Map
            markerCoords={markerCoords}
            containerStyle={{
              height: '1385px',
              borderRadius: 20,
            }}
            zoom={[10]}
            fitBoundsOptions={{ maxZoom: 30 }}
            mapData={mapData}
          />
        </MapContainer>
      )
    })
  }
}

export { ConnectedSearchResultsContainer as SearchResults }
