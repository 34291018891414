import { createModule } from '@soltalabs/stateless'

import { ProductService } from './service'

const INITIAL_STATE = Object.freeze({
  selectedProduct: null,
})

const fetchProductById = (module) => async (id, location) => {
  let product

  module.setState({ selectedListing: null })

  try {
    product = await ProductService.get(id, location)
  } catch (error) {
    throw new Error('There was an error loading this event. Try again in a little bit.')
  }

  module.setState({
    selectedProduct: product,
  })
}

const productModule = createModule({
  name: 'product',
  initialState: INITIAL_STATE,
  decorators: {
    fetchProductById,
  },
})

export { productModule }
