// this is the listing page showing listings according to the design
import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PulseLoader from 'react-spinners/PulseLoader'

import FilterContainer from './Filter'
import ResultListContainer from './ResultListContainer'

import { ListCard } from 'lib/components'
import { styled, s } from 'lib/styled'
import { colors } from 'product.config'
import { getParamsFromUrl } from 'utils/url'

const LoadingContainer = styled.div(
  s('relative w-full flex justify-center items-center', {
    maxHeight: '50vh',
    minWidth: '791px',
  })
)

const BlockContainer = styled.div(s('block'), { marginTop: 15 })

function SearchResults({
  setSortOrder,
  setSortBy,
  searchResults,
  isSearchingListings,
}) {
  const urlLocation = useLocation()
  const history = useHistory()

  const searchItems = useMemo(() => {
    const toDetailsPage = (sourceType, id) => {
      if (sourceType.toLowerCase() === 'products') {
        history.push(`/product/${id}`)
      } else {
        history.push(`/listing/${id}`)
      }
    }
    return searchResults.map(
      ({ _id, name, sourceType, location, address, price, distance }) => {
        return (
          <div key={_id}>
            <ListCard
              title={name}
              location={location}
              address={address.suburb}
              price={price}
              distance={distance}
              sourceType={sourceType}
              onClick={() => toDetailsPage(sourceType, _id)}
            />
          </div>
        )
      }
    )
  }, [searchResults])

  if (isSearchingListings) {
    return (
      <BlockContainer>
        <FilterContainer>SearchResults</FilterContainer>
        <LoadingContainer>
          <PulseLoader size={15} color={colors.primary} />
        </LoadingContainer>
      </BlockContainer>
    )
  }

  const handleSort = ({ name }) => {
    const { sortBy, sortOrder } = getParamsFromUrl(
      ['sortBy', 'sortOrder'],
      urlLocation.search
    )
    let sortByParam = sortBy
    let sortOrderParam = sortOrder

    if (sortBy === name) {
      sortOrderParam = parseInt(sortOrder, 10) === 1 ? -1 : 1
    } else {
      sortByParam = name
      sortOrderParam = 1
    }

    setSortBy(sortByParam)
    setSortOrder(sortOrderParam)
  }

  return (
    <BlockContainer>
      <FilterContainer handleSort={handleSort}>SearchResults</FilterContainer>
      <ResultListContainer>{searchItems}</ResultListContainer>
    </BlockContainer>
  )
}

export { SearchResults as ActivityList }
