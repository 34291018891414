import React, { useRef } from 'react'

import { useOnClickOutside } from '../../../hooks'

import { SearchBar } from 'components/search'
import { styled, s } from 'lib/styled'

const SearchBarContainer = styled.div(
  s('fixed flex-1 min-w-4/5 justify-center items-center z-5'),
  {
    top: '10vh',
  }
)

function HeaderMainSearchBar({ onDismiss, dismissWhitelist = [] }) {
  const ref = useRef()
  useOnClickOutside(ref, dismissWhitelist, onDismiss)

  return (
    <SearchBarContainer ref={ref}>
      <SearchBar />
    </SearchBarContainer>
  )
}

export { HeaderMainSearchBar }
