import 'whatwg-fetch'
import ky from 'ky'

import { CONFIG } from '../config'

const http = ky.create({
  prefixUrl: `${CONFIG.API.URL}`,
  headers: {
    'content-type': 'application/json',
  },
})

export { http }
