import { ThemeProvider } from '@emotion/react'
import { StylesProvider } from '@material-ui/core'
import { createBrowserHistory as createHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import reportWebVitals from './reportWebVitals'

import App from 'App'
import { breakpoints } from 'styles/breakpoints'

const history = createHistory()

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <ThemeProvider theme={{ breakpoints }}>
        <StylesProvider injectFirst>
          <App />
        </StylesProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
