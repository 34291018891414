/* eslint-disable complexity */
import { isNilOrEmpty, path } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { createUrlSearchParams } from './utils'

import LocationMarker from 'assets/locationMarker.svg'
import QuickSearchIcon from 'assets/rectangles.svg'
import { ReactComponent as SearchIcon } from 'assets/search.svg'
import MagnifierIcon from 'assets/smallMagnifier.svg'
import SearchContext from 'contexts/SearchHistoryContext'
import { TextWithIcon } from 'lib/components'
import { styled, s, smaller, larger } from 'lib/styled'
import { currentLocation$ } from 'modules/location'
import { mapModule, locationSuggestions$ } from 'modules/map'
import {
  autoCompletions$,
  searchSuggestions$,
  location$,
  searchParams$,
  searchModule,
  locationAddress$,
} from 'modules/search'
import { isOnMobile$, uiModule } from 'modules/ui'
import { colors } from 'product.config'
import { STYLE_CONFIG } from 'styles/config'

const service = new google.maps.places.PlacesService(
  document.createElement('div', {
    width: 0,
    height: 0,
  })
)

const Container = styled.div(s('relative ml-a mr-a w-4/5'), {
  maxWidth: STYLE_CONFIG.MAX_WIDTH,
  minWidth: 558,
})

const MobileContainer = styled.div(
  s('relative', {
    padding: '0 40px',
  })
)

const searchBarStyles = s(
  'relative flex flex-row rounded-lg bg-white m-0 justify-between items-center z-1',
  {
    filter: 'drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15))',
  }
)

const SearchBarTitle = styled.span(
  s('block text-black text-3xl font-semibold mb-4'),
  {
    lineHeight: '120%',
    letterSpacing: '-0.01em',
  },
  smaller('md')(s('text-lg'))
)

const SearchContainer = styled.div(searchBarStyles, s('p-4'))

const MobileSearchContainer = styled.div(
  searchBarStyles,
  s('flex justify-between p-3 mb-4')
)

const KeywordContainer = styled.div(
  s('relative flex flex-row items-center', {
    width: '45%',
  })
)

const KeywordSearchContainer = styled.div(
  s('relative flex flex-column flex-grow-1 mr-2', {
    maxWidth: '100%',
  }),
  smaller('sm')(
    s('ml-0', {
      borderColor: colors.grey[300],
    })
  )
)

const LocationContainer = styled.div(
  s('relative flex flex-row items-center', { width: '55%' })
)

const LocationSearchContainer = styled.div(
  s('relative flex flex-column flex-grow-1 w-1/2'),
  larger('lg')(s('ml-4'))
)

const Divider = styled.div(
  s('border-0 border-r-1 border-solid h-4 mr-4', {
    borderColor: '#DEE5EF',
    opacity: 0.8,
  })
)

const HorizontalDivider = styled.hr(
  s('border-1 border-t-1 border-solid w-full', {
    borderColor: '#DEE5EF',
    opacity: 0.8,
  })
)

const Title = styled.span(
  s('text-black text-2xl font-normal'),
  {
    color: colors.grey[500],
  },
  smaller('md')(s('text-lg'))
)

const Input = styled.input(
  s('text-black text-xl font-normal border-0'),
  {
    ':focus': {
      outline: '0 !important',
    },
  },
  smaller('md')(s('text-base'))
)

const AutoCompleteContainer = styled.div(
  ({ shouldPositionRight }) =>
    s(
      'absolute flex flex-column rounded-lg bg-white p-4 justify-center z-3 h-a w-full',
      {
        filter: 'drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15))',
        left: shouldPositionRight ? 'auto' : 0,
        right: shouldPositionRight ? 0 : 'auto',
        minWidth: shouldPositionRight ? 0 : '20vw',
        top: 90,
      }
    ),
  smaller('sm')({
    top: 'auto',
  })
)

const SearchButton = styled.button(
  s('w-4 h-4 min-h-4 min-w-4 block ml-2 rounded-md flex justify-center items-center'),
  {
    border: 0,
    outline: 0,
    background: '#943798',
    cursor: 'pointer',
  },
  smaller('sm')(s('w-3 h-3 mr-0')),
  {
    svg: {
      width: '70%',
    },
    '&[disabled]': {
      opacity: 0.2,
    },
  }
)

const SearchSwitchContainer = styled.div(s('absolute flex-1 flex-column h-a w-a z-0'), {
  bottom: -27,
  right: '40px',
  left: 'auto',
})

const RecentlySearched = styled.div(s('flex flex-column'))

const SwitchText = styled.span(s('text-xs'), {
  textDecoration: 'underline',
  cursor: 'pointer',
  userSelect: 'none',
  color: colors.grey[300],
})

const ConnectedSearchBar = connect(() => ({
  autoCompletions: autoCompletions$,
  searchSuggestions: searchSuggestions$,
  locationSuggestions: locationSuggestions$,
  location: location$,
  searchParams: searchParams$,
  locationAddress: locationAddress$,
  isOnMobile: isOnMobile$,
  currentLocation: currentLocation$,
}))(SearchBar)

function SearchBar({
  autoCompletions,
  searchSuggestions,
  locationSuggestions,
  location,
  searchParams,
  locationAddress,
  isOnMobile,
  currentLocation,
}) {
  const [isSearchingLocation, setIsSearchingLocation] = useState(false)
  const [isSearchingKeywords, setIsSearchingKeywords] = useState(false)

  const [isSelectingLocation, setIsSelectingLocation] = useState(false)
  const [isSelectingKeyword, setIsSelectingKeyword] = useState(false)

  const [shouldHideKeywordContainer, setShouldHideKeywordContainer] = useState(false)

  const [searchHistory, dispatch] = useContext(SearchContext)

  const history = useHistory()

  const handleEnterPressOnSearch = (e) => {
    if (e.key === 'Enter') {
      handleOnSearch(searchParams, location)
    }
  }
  useEffect(() => {
    searchModule.fetchSearchSuggestions()
  }, [])

  const handleOnKeywordChange = (value) => {
    searchModule.setSearchParams(null, { query: value })
    searchModule.fetchAutoCompletions(value)
  }

  const handleOnLocationSearch = async (value) => {
    searchModule.setLocationAddress(null, value)
    if (value) mapModule.fetchLocationSuggestions(value, currentLocation)
  }

  const handleOnSelectKeyword = (value) => {
    searchModule.setSearchParams(null, { query: value })
    handleOnSearch({ query: value }, location)
  }

  const handleOnSelectLocation = async (location, isSelectingSearchHistory) => {
    dispatch({ type: 'ADD', location })
    const place = await new Promise((resolve, reject) => {
      service.getDetails(
        {
          placeId: location.place_id,
        },
        (place, status) => {
          if (
            status !== google.maps.places.PlacesServiceStatus.OK ||
            !place?.address_components ||
            place.address_components.length === 0
          ) {
            reject(
              new Error('Error occurred after selecting address. Please try again')
            )
          }
          resolve(place)
        }
      )
    })

    searchModule.setLocationAddress(null, path(['formatted_address'])(place))

    const coords = {
      lat: place?.geometry?.location?.lat(),
      lng: place?.geometry?.location?.lng(),
    }

    searchModule.setLocation(null, coords)
    handleOnSearch(searchParams, coords, isSelectingSearchHistory)
  }

  const searchListsAndCreateUrl = (searchParams, location) => {
    searchModule.searchListings()
    const urlSearchParams = createUrlSearchParams({ ...searchParams, ...location })
    history.push(`/search?${urlSearchParams.toString()}`)
  }

  const handleOnSearch = (searchParams, location, isSelectingSearchHistory) => {
    uiModule.updateHeaderMainSearchBarVisibility(null, false)
    searchModule.setIsSearchStateLost(null, false)

    const userSearchForNearBy =
      isNilOrEmpty(locationAddress) && !isSelectingSearchHistory
    const searchForListings = (location) => {
      if (location) searchModule.setLocation(null, location)
      searchListsAndCreateUrl(searchParams, location)
    }

    if (userSearchForNearBy) {
      if (isNilOrEmpty(currentLocation)) return
      searchForListings(currentLocation)
    } else {
      searchModule.setSearchParams(null, searchParams)
      searchForListings(location)
    }
  }

  const getAutocompletions = () => {
    if (isNilOrEmpty(autoCompletions) || isNilOrEmpty(searchParams.query))
      return searchSuggestions.map((option, index) => (
        <TextWithIcon
          text={option.name}
          icon={QuickSearchIcon}
          textSize={14}
          key={index}
          handleOnClick={() => handleOnSelectKeyword(option.title)}
        />
      ))

    return autoCompletions.map((option, index) => (
      <TextWithIcon
        text={option}
        icon={MagnifierIcon}
        textSize={14}
        key={index}
        handleOnClick={() => handleOnSelectKeyword(option)}
      />
    ))
  }

  const getLocationSuggestions = () => {
    if (isNilOrEmpty(locationSuggestions) || isNilOrEmpty(locationAddress))
      return (
        <TextWithIcon
          text={'Neighbor nearby'}
          icon={LocationMarker}
          textSize={14}
          handleOnClick={() => handleOnSearch(searchParams, location)}
        />
      )
    return locationSuggestions.map((location, index) => (
      <TextWithIcon
        text={path(['description'])(location)}
        icon={LocationMarker}
        textSize={14}
        key={index}
        handleOnClick={() => handleOnSelectLocation(location)}
      />
    ))
  }

  const getKeywordsSearch = () => {
    return (
      <KeywordSearchContainer>
        <Title>Keywords</Title>
        <Input
          placeholder="What are you looking for?"
          onFocus={() => setIsSearchingKeywords(true)}
          onBlur={() => setIsSearchingKeywords(false)}
          value={searchParams.query || ''}
          onChange={(event) => handleOnKeywordChange(event.target.value)}
          onKeyUp={handleEnterPressOnSearch}
        />
      </KeywordSearchContainer>
    )
  }

  const getLocationSearch = () => {
    return (
      <LocationSearchContainer>
        <Title>Location</Title>
        <Input
          placeholder="Where are you looking at?"
          onFocus={() => setIsSearchingLocation(true)}
          onBlur={() => setIsSearchingLocation(false)}
          onChange={(event) => handleOnLocationSearch(event.target.value)}
          value={locationAddress || ''}
          onKeyUp={handleEnterPressOnSearch}
        />
      </LocationSearchContainer>
    )
  }

  const getLocationSearchHistory = () => {
    return searchHistory.map((location, index) => {
      return (
        <TextWithIcon
          text={path(['description'])(location)}
          icon={LocationMarker}
          textSize={14}
          key={index}
          handleOnClick={() => {
            handleOnSelectLocation(location, true)
          }}
        />
      )
    })
  }

  const getKeywordsSearchResult = () => {
    return (
      <AutoCompleteContainer
        hidden={!isSearchingKeywords && !isSelectingKeyword}
        shouldPositionRight={!isSearchingKeywords && !isSelectingKeyword}
        onMouseOver={() => setIsSelectingKeyword(true)}
        onMouseLeave={() => setIsSelectingKeyword(false)}
      >
        {getAutocompletions()}
      </AutoCompleteContainer>
    )
  }

  const getLocationSearchResult = () => {
    return (
      <AutoCompleteContainer
        onMouseOver={() => setIsSelectingLocation(true)}
        onMouseLeave={() => setIsSelectingLocation(false)}
        hidden={!isSearchingLocation && !isSelectingLocation}
        shouldPositionRight={isSearchingLocation || isSelectingLocation}
      >
        {getLocationSuggestions()}
        <RecentlySearched>
          {searchHistory.length > 0 ? (
            <>
              <HorizontalDivider />
              <span style={{ fontSize: 12 }}>Recently Searched</span>
            </>
          ) : null}
          {getLocationSearchHistory()}
        </RecentlySearched>
      </AutoCompleteContainer>
    )
  }

  const onSearchSwapRequest = () => {
    setShouldHideKeywordContainer(!shouldHideKeywordContainer)
  }

  if (isOnMobile)
    return (
      <MobileContainer>
        <MobileSearchContainer>
          {shouldHideKeywordContainer ? getLocationSearch() : getKeywordsSearch()}

          <SearchButton
            onClick={() => handleOnSearch(searchParams, location)}
            disabled={isNilOrEmpty(currentLocation)}
          >
            <SearchIcon />
          </SearchButton>
        </MobileSearchContainer>

        <SearchSwitchContainer onClick={onSearchSwapRequest}>
          <SwitchText>{`Search by ${
            shouldHideKeywordContainer ? 'keywords' : 'location'
          }`}</SwitchText>
        </SearchSwitchContainer>

        <div style={{ position: 'relative' }}>
          {shouldHideKeywordContainer
            ? getLocationSearchResult()
            : getKeywordsSearchResult()}
        </div>
      </MobileContainer>
    )

  return (
    <Container isSearchingLocation={isSearchingLocation}>
      <SearchBarTitle>{"Bula, let's start your holiday here:"}</SearchBarTitle>
      <SearchContainer>
        <KeywordContainer>
          {getKeywordsSearch()}
          {isSearchingKeywords || isSelectingKeyword ? getKeywordsSearchResult() : null}

          <Divider />
        </KeywordContainer>

        <LocationContainer>
          {getLocationSearch()}
          {isSearchingLocation || isSelectingLocation
            ? getLocationSearchResult()
            : null}
          <SearchButton
            onClick={() => handleOnSearch(searchParams, location)}
            disabled={isNilOrEmpty(currentLocation)}
          >
            <SearchIcon />
          </SearchButton>
        </LocationContainer>
      </SearchContainer>
    </Container>
  )
}

export { ConnectedSearchBar as SearchBar }
