import React from 'react'

import { styled, s } from 'lib/styled'

const FilterNames = ['Distance', 'Price', 'Categories']

const Container = styled.div(s('flex justify-start'), {
  width: 351,
  height: 52,
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '120%',
  padding: '4px 0px',
  gap: 8,
})

const Filter = styled.div({
  border: '1px solid #DEE5EF',
  borderRadius: '25px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4px 18px',
  gap: 10,
  '&:hover': {
    cursor: 'pointer',
  },
})

const FilterContainer = ({ handleSort }) => {
  const Filters = FilterNames.map((name, index) => {
    const params = {
      name,
    }
    return (
      <Filter key={index} onClick={() => handleSort(params)}>
        {name}
      </Filter>
    )
  })
  return <Container>{Filters}</Container>
}

export default FilterContainer
