import React, { useRef } from 'react'

import { CONFIG } from '../../../config'
import { useOnClickOutside } from '../../../hooks'

import { CommunityDropdownItem } from './CommunityDropdownItem'

import { styled, s } from 'lib/styled'

const Container = styled.div(({ visible }) =>
  s('absolute shadow-lg py-2 rounded-md', {
    backgroundColor: '#FFF',
    top: '3rem',
    right: '1rem',
    transition: 'all 200ms ease-in-out',
    opacity: visible ? '1' : '0',
    pointerEvents: visible ? 'auto' : 'none',
  })
)

const Link = styled.a({
  color: 'inherit',
  '&:hover': {
    color: 'inherit',
  },
})

function CommunityDropdown({
  onDismiss,
  dismissWhitelist = [],
  visible,
  toProviderLandingPage,
}) {
  const ref = useRef()
  useOnClickOutside(ref, dismissWhitelist, onDismiss)

  return (
    <Container ref={ref} visible={visible}>
      <Link href={`${CONFIG.WIX.URL}blog`}>
        <CommunityDropdownItem text={'Blogs'} />
      </Link>
      {/* <Link href={`${CONFIG.WIX.URL}families`}>
        <CommunityDropdownItem text={'Bula Benefits'} />
  </Link> */}
      <CommunityDropdownItem
        text={'Become a creator'}
        hasBottomBorder={false}
        onClick={toProviderLandingPage}
      />
    </Container>
  )
}

export { CommunityDropdown }
