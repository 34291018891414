import React from 'react'

import { ReactComponent as Badge } from 'assets/appleStore.svg'
import { styled, s } from 'lib/styled'

const AppleBadgeLink = styled.a(s('mr-2'))

function AppStoreBadge() {
  return (
    <AppleBadgeLink
      href={'https://apps.apple.com/us/app/bula-fiji/id1635889184'}
      target={'_blank'}
      rel="noreferrer"
    >
      <Badge height={'64px'} />
    </AppleBadgeLink>
  )
}

export { AppStoreBadge }
