import { prop } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { mapModule } from './module'

const { state$ } = mapModule

const locationSuggestions$ = select(state$, (state) =>
  prop('locationSuggestions', state)
)

export { locationSuggestions$ }
