import React, { useState } from 'react'
import ReactBnbGallery from 'react-bnb-gallery'
import { Player } from 'video-react'

import { LISTING_MEDIA_TYPES } from '../../../utils/constants'

import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Container = styled.div(s('relative bg-white mt-10 mb-10 ', {}))

const MediaContainer = styled.div(
  s('relative flex flex-row items-center justify-between w-full h-full rounded-xl', {
    overflow: 'hidden',
  })
)

const BigPlayerContainer = styled.div(s('relative justify-center', { height: 480 }))
const PlayerContainer = styled.div(s('relative justify-center', { height: 232 }))

const BigImage = styled.img(
  s('w-full', {
    height: '480px',
    objectFit: 'cover',
    cursor: 'pointer',
  })
)
const GridContainer = styled.div(s('grid w-full h-full ml-4'), {
  gridTemplateColumns: '1fr 1fr',
  columnGap: '16px',
  rowGap: '16px',
})
const Image = styled.img(
  s('w-full', {
    height: '232px',
    objectFit: 'cover',
    cursor: 'pointer',
  })
)

const MediaRow = styled(Row)(s('w-full'))

const generateMedia = (media, openGallery) => (
  <Col span={12}>
    <GridContainer>
      {media.slice(1, 5).map(({ mediaType, content, id }, index) => {
        if (mediaType === LISTING_MEDIA_TYPES.VIDEO) {
          return (
            <div key={id}>
              <PlayerContainer>
                <Player>
                  <source src={content} />
                </Player>
              </PlayerContainer>
            </div>
          )
        }

        return (
          <div
            key={id}
            style={{ position: 'relative', display: 'flexs', width: '100%' }}
          >
            <Image src={content} onClick={() => openGallery(index + 1)} />
          </div>
        )
      })}
    </GridContainer>
  </Col>
)

const generateInitialMedia = ({ mediaType, content, id }, openGallery) => {
  if (mediaType === LISTING_MEDIA_TYPES.VIDEO) {
    return (
      <Col key={id} span={12}>
        <BigPlayerContainer>
          <Player>
            <source src={content} />
          </Player>
        </BigPlayerContainer>
      </Col>
    )
  }

  return (
    <Col key={id} span={12}>
      <div style={{ position: 'relative', display: 'flexs', width: '100%' }}>
        <BigImage src={content} onClick={() => openGallery(0)} />
      </div>
    </Col>
  )
}

function generateGallery(data, openGallery) {
  return (
    <MediaContainer>
      <MediaRow>
        {generateInitialMedia(data[0], openGallery)}
        {generateMedia(data, openGallery)}
      </MediaRow>
    </MediaContainer>
  )
}

const generateImageSet = (data) => {
  return data.map((item) => ({
    photo: item.content,
    thumbnail: item.content,
  }))
}

function ActivityGallery({ data, maxWidth }) {
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const openGallery = (index) => {
    setPhotoIndex(index)
    setIsOpen(true)
  }

  const images = generateImageSet(data)
  return (
    <Container>
      {generateGallery(data, openGallery, maxWidth)}
      <ReactBnbGallery
        show={isOpen}
        photos={images}
        activePhotoIndex={photoIndex}
        onClose={() => setIsOpen(false)}
      />
    </Container>
  )
}

export { ActivityGallery }
