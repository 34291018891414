import { prop, path } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { productModule } from './module'

import { REQUEST_STATUS } from 'utils/constants'

const { state$, effectState$ } = productModule

const selectedProduct$ = select(state$, (state) => prop('selectedProduct', state))

const fetchProductByIdStatus$ = select(
  selectedProduct$,
  effectState$,
  (selectedListing, effectState) =>
    path(['fetchListingById', path(['id'])(selectedListing), 'status'])(effectState)
)
const isFetchingProductById$ = select(
  fetchProductByIdStatus$,
  (status) => status === REQUEST_STATUS.PENDING
)

export { selectedProduct$, isFetchingProductById$ }
