import React from 'react'

import { JumboItem } from '../../../lib/components/surfaces'
import playzToHelpData from '../layoutData/bulaToHelpData.json'

import { SectionTitleBlock } from './SectionTitleBlock'

import { styled, s } from 'lib/styled'

const Container = styled.div(s('mb-24'), {
  marginTop: 300,
  '@media screen and (max-width: 1000px)': {
    marginTop: 80,
  },
})

const Jumbotron = styled.div({})

const getJumboItems = (playzToHelpData) =>
  playzToHelpData.map(({ title, intro, imgSrc, imgMetaText, direction }, idx) => (
    <JumboItem
      title={title}
      intro={intro}
      imgSrc={imgSrc}
      imgMetaText={imgMetaText}
      direction={direction}
      key={idx}
    />
  ))

function BulaToHelpSection() {
  return (
    <Container>
      <SectionTitleBlock
        title="Bula is here to help"
        intro="Our platform supports all activity providers to improve engagement and reach more families and travellers."
      />

      <Jumbotron>{getJumboItems(playzToHelpData)}</Jumbotron>
    </Container>
  )
}

export { BulaToHelpSection }
