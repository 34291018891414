import { prop } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { uiModule } from './module'

const { state$ } = uiModule

const isMainSearchBarVisible$ = select(state$, (state) =>
  prop('isMainSearchBarVisible', state)
)

const isHeaderMainSearchBarVisible$ = select(state$, (state) =>
  prop('isHeaderMainSearchBarVisible', state)
)

const isHeaderSimpleSearchBarVisible$ = select(
  state$,
  (state) =>
    !prop('isHeaderMainSearchBarVisible', state) &&
    !prop('isMainSearchBarVisible', state)
)

const isOnMobile$ = select(state$, (state) => prop('screenWidth', state) < 768)

const screenWidth$ = select(state$, (state) => prop('screenWidth', state))

const isMobileMenuOpen$ = select(state$, (state) => prop('isMobileMenuOpen', state))

export {
  isMainSearchBarVisible$,
  isHeaderMainSearchBarVisible$,
  isHeaderSimpleSearchBarVisible$,
  isOnMobile$,
  screenWidth$,
  isMobileMenuOpen$,
}
