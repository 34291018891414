import parse from 'html-react-parser'
import React from 'react'

import { AppStoreBadge } from '../AppStoreBadge'
import { PlayStoreBadge } from '../PlayStoreBadge'

import { Row, Col } from 'lib/react-grid'
import { styled, s, smaller } from 'lib/styled'

const Container = styled.div(s('relative flex flex-row w-full'))

const BadgeContainer = styled.div(s('flex flex-row w-full'))

const InformationContainer = styled.div(
  s('flex flex-column border-1 border-solid border-grey-light p-8 rounded-2xl w-full')
)

const ItemContainer = styled.div(s('relative flex flex-row w-full border-0 pb-2 mb-4'))

const HighlightedItem = styled.div(
  s('flex rounded-2xl m-2 py-1 px-4 ml-0', { background: '#DEE5EF' })
)

const PriceContainer = styled.div(
  s('relative flex flex-row w-full border-0 pb-2 mb-4', {
    color: '#943798',
    fontSize: '24px',
  })
)

const DownloadMessageContainer = styled.div(s('pb-2 mb-4'), { fontSize: '18px' })

const Category = styled.div(
  s('flex rounded-md m-2 py-1 px-4 ml-0', { background: '#DEE5EF' })
)

const KeywordText = styled.div(s('text-sm'))

const Title = styled.h3(
  s('text-black font-semibold text-5xl p-0 m-0'),
  smaller('md')(s('text-xl'))
)

const Description = styled.span(s('text-black text-base font-normal block'))

const Line = styled.hr(s('mb-8 text-grey-light'), { opacity: 0.2 })

const Header = styled.p(
  s('text-black font-semibold p-0 m-0 mb-4', {
    fontSize: '28px',
  }),
  smaller('md')(s('text-xl'))
)

function ActivitySummary({ data, isOnMobile }) {
  const generateInformationContainer = () => {
    const price = data?.price ?? data?.lowestPrice
    return (
      <InformationContainer>
        <PriceContainer>{price === 0 ? `FREE` : `$${price} / person`}</PriceContainer>
        <DownloadMessageContainer>
          To check availability and book this activity, please download the Bula app.
        </DownloadMessageContainer>
        <BadgeContainer>
          <AppStoreBadge />
          <PlayStoreBadge />
        </BadgeContainer>
      </InformationContainer>
    )
  }
  return (
    <Container>
      <Row gutter={20} style={{ width: '100%' }}>
        <Col md={24} lg={16} xl={16} style={{ width: '100%' }}>
          <Title>{data.title ?? data.name}</Title>
          <ItemContainer>
            {data.categories?.map((item, index) => (
              <Category key={index}>
                <KeywordText>{item}</KeywordText>
              </Category>
            ))}
          </ItemContainer>
          <Line />

          {isOnMobile ? generateInformationContainer() : null}

          <Header>Overview</Header>
          <Description>{parse(data.description)}</Description>
          <ItemContainer>
            {data.keywords?.map((item, index) => (
              <HighlightedItem key={index}>
                <KeywordText>{item}</KeywordText>
              </HighlightedItem>
            ))}
          </ItemContainer>
          <Line />
        </Col>

        <Col md={24} lg={8} xl={8} style={{ width: '100%' }}>
          {isOnMobile ? null : generateInformationContainer()}
        </Col>
      </Row>
    </Container>
  )
}

export { ActivitySummary }
