import React from 'react'

import { CONFIG } from '../../../config'

import { styled, s } from 'lib/styled'
import { colors } from 'product.config'
import { toExternalUrl } from 'utils/toExternalUrl'

const HeroTextBlockContainer = styled.div(
  s('flex flex-column justify-start items-start max-w-32', {
    '@media screen and (max-width: 780px)': {
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: '4rem',
    },
    '@media screen and (max-width: 520px)': {
      paddingLeft: 16,
      paddingRight: 16,
    },
  })
)

const TitleText = styled.p(
  s('text-5xl font-bold m-0 mb-2', {
    lineHeight: '66px',
    color: colors.primary,
    '@media screen and (max-width: 1000px)': {
      fontSize: '2rem',
      marginRight: '1rem',
      lineHeight: '50px',
    },
    '@media screen and (max-width: 780px)': {
      fontSize: '2.5rem',
    },
    '@media screen and (max-width: 520px)': {
      fontSize: '1.8rem',
    },
  })
)

const Intro = styled.p(s('text-xl font-normal mb-24'), {
  lineHeight: '30px',
  color: colors.primary,
  '@media screen and (max-width: 1000px)': {
    fontSize: '1.2rem',
  },
  '@media screen and (max-width: 780px)': {
    marginBottom: '4rem',
  },
})

const CtaBtn = styled.button(
  s('text-xl font-bold text-white rounded-md border-0 px-24 py-4'),
  {
    background: colors.primary,
    cursor: 'pointer',
    '@media screen and (max-width: 1000px)': {
      fontSize: '1rem',
    },
    '@media screen and (max-width: 780px)': {
      fontSize: '1.5rem',
    },
    '@media screen and (max-width: 520px)': {
      fontSize: '1rem',
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
    },
  }
)

function HeroTextBlock() {
  return (
    <HeroTextBlockContainer>
      <TitleText>Want to boost your business?</TitleText>
      <Intro>
        Bula is your ideal platform to show off your tourism attraction to the world!
      </Intro>
      <CtaBtn onClick={() => toExternalUrl(`${CONFIG.PROVIDER.URL}signup`)}>
        Let&apos;s start
      </CtaBtn>
    </HeroTextBlockContainer>
  )
}

export { HeroTextBlock }
