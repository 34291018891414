import { filter, isNilOrEmpty, prop, path } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PulseLoader from 'react-spinners/PulseLoader'

import { LISTING_MEDIA_TYPES } from '../../utils/constants'
import { ActivityGallery } from '../common/ActivityDetails/ActivityGallery'
import { ActivityLocations } from '../common/ActivityDetails/ActivityLocations'
import { ActivitySummary } from '../common/ActivityDetails/ActivitySummary'
import { ProviderDetails } from '../common/ActivityDetails/ProviderDetails'

import ShareIcon from 'assets/share.svg'
import { useQuery } from 'hooks'
import { Alert } from 'lib/components'
import { styled, s, smaller } from 'lib/styled'
import {
  selectedListing$,
  isFetchingListingById$,
  listingModule,
} from 'modules/listing'
import { currentLocation$ } from 'modules/location'
import { providerModule } from 'modules/provider'
import { isOnMobile$, screenWidth$ } from 'modules/ui'
import { colors } from 'product.config'

const Container = styled.div(s('pb-8 h-full w-full', {}), smaller('md')(s('px-5')))

const LoadingContainer = styled.div(
  s('relative w-full flex justify-center items-center', {
    height: '80vh',
  })
)

const ShareContainer = styled.div(s('flex justify-end mt-4'), {
  '&:hover': { cursor: 'pointer' },
})

const AlertContainer = styled.div(s('flex justify-end mt-2'))

const ConnectedListingDetailsPage = connect(() => ({
  selectedListing: selectedListing$,
  isFetchingListingById: isFetchingListingById$,
  screenWidth: screenWidth$,
  isOnMobile: isOnMobile$,
  currentLocation: currentLocation$,
}))(ListingDetailsPage)

function ListingDetailsPage({
  selectedListing,
  isFetchingListingById,
  screenWidth,
  isOnMobile,
  currentLocation,
}) {
  const [fetchListingByIdError, setFetchListingByIdError] = useState(undefined)
  const [showListingShared, setShouldShowListingShared] = useState(false)
  const params = useParams()
  const query = useQuery()

  const listingId = path(['id'])(params)
  const listingSource = query.get('source')

  useEffect(() => {
    listingModule
      .fetchListingById(listingId, listingSource, currentLocation)
      .catch((error) => {
        setFetchListingByIdError(error)
      })
  }, [])

  const errorMessage = prop('message')(fetchListingByIdError)

  if (errorMessage) return <p>{errorMessage}</p>

  if (isFetchingListingById || isNilOrEmpty(selectedListing))
    return (
      <LoadingContainer>
        <PulseLoader size={15} color={colors.primary} />
      </LoadingContainer>
    )

  const mediaList = filter(
    (item) =>
      item.mediaType === LISTING_MEDIA_TYPES.PHOTO ||
      item.mediaType === LISTING_MEDIA_TYPES.VIDEO,
    selectedListing.media
  )

  const share = async () => {
    setShouldShowListingShared(true)
    const urlToShare = document.location.href
    await navigator.clipboard.writeText(urlToShare)

    setTimeout(() => setShouldShowListingShared(false), 5000)
  }
  return (
    <Container>
      <AlertContainer>
        <Alert type="info" text="Copied to clipboard." visible={showListingShared} />
      </AlertContainer>
      <ShareContainer onClick={() => share()}>
        <img src={ShareIcon} />
        <span>Share</span>
      </ShareContainer>
      <ActivityGallery data={mediaList} maxWidth={screenWidth} />
      <ActivitySummary data={selectedListing} isOnMobile={isOnMobile} />
      <ActivityLocations
        locations={selectedListing.availabilities}
        isListing
        isOnMobile={isOnMobile}
        selectedActivity={selectedListing}
      />
      <ProviderDetails
        provider={selectedListing.provider}
        onContactProvider={providerModule.contactProvider}
      />
    </Container>
  )
}

export { ConnectedListingDetailsPage as ListingDetailsPage }
